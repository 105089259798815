import React from "react";
import Grid from "@mui/material/Grid";
import { useFormState } from "react-final-form";
import { isRequired } from "../../forms/validators/isRequired";
import dayjs from "dayjs";
import { filterDate } from "../utils/filterDate";
import { MarathonSelectField } from "../../forms/components/MarathonSelectField";
import MenuItem from "@mui/material/MenuItem";
import { useAsyncData } from "@torqit/torq-tools-react";
import { PickupInShowroomFieldsProps } from "./PickupInShowroomFields";
import { DatePickerField } from "../../forms/components/DatePickerField";
import { ShippingInformationSave } from "../types/Checkout";
import { getAvailablePickupTimesForDate } from "../api/getAvailablePickupTimesForDate";
import { Skeleton } from "@mui/material";

export type PickupDateTimeFieldsProps = Pick<
  PickupInShowroomFieldsProps,
  "fieldNames" | "shippingInformation"
>;

export const PickupDateTimeFields = ({
  fieldNames,
  shippingInformation,
}: PickupDateTimeFieldsProps) => {
  const { values } = useFormState<ShippingInformationSave>();

  const { data: availablePickupTimesForDate, isLoading: availablePickupTimesForDateIsLoading } =
    useAsyncData(
      () => getAvailablePickupTimesForDate(values?.pickupDate ?? ""),
      [values.pickupDate]
    );

  return (
    <>
      <Grid
        container
        spacing={4}
        pb={4}
      >
        <Grid
          item
          xs={12}
        >
          <div className="text-base font-heavy underline mb-2">
            <a
              target="_blank"
              href={shippingInformation.branchMapUrl}
            >
              {shippingInformation.branchAddress}
            </a>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <DatePickerField
            name={fieldNames.pickupDate}
            textFieldProps={{
              required: true,
              fullWidth: true,
              label: "Scheduled Pickup Date",
            }}
            datePickerProps={{
              minDate: dayjs(),
              maxDate: dayjs().add(1, "month"),
              shouldDisableDate: (date) =>
                !filterDate(date.toDate(), shippingInformation.invalidPickupDates),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          {availablePickupTimesForDateIsLoading ? (
            <Skeleton height={62} />
          ) : (
            <MarathonSelectField
              name={fieldNames.pickupTime}
              fieldProps={{ validate: isRequired }}
              textFieldProps={{ required: true, label: "Select a time", sx: { minWidth: "100%" } }}
            >
              {availablePickupTimesForDate && availablePickupTimesForDate.length > 0 ? (
                availablePickupTimesForDate.map((pickupTime) => (
                  <MenuItem
                    key={pickupTime}
                    value={pickupTime}
                  >
                    {pickupTime}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No pickup times</MenuItem>
              )}
            </MarathonSelectField>
          )}
        </Grid>
      </Grid>
    </>
  );
};
