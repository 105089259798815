import React from "react";
import { fieldName } from "../../../forms/utils/fieldName";
import { PaymentData } from "../api/savePaymentData";
import { PaymentOptions } from "../types/PaymentOptions";
import { MarathonRadioField } from "../../../forms/components/MarathonRadioField";
import { RadioOption } from "../../../common/components/MarathonRadioGroup";
import { PaymentStep } from "../../types/Checkout";

export type SelectPaymentOptionProps = {
  paymentStepInfo: PaymentStep;
};

export const SelectPaymentOption = ({ paymentStepInfo }: SelectPaymentOptionProps) => {
  const options: RadioOption[] = [];
  if (paymentStepInfo.isTermsAvailable) {
    options.push({
      value: PaymentOptions.AddPaymentToAccount,
      label: "Add payment to my account",
    });
  }
  if (paymentStepInfo.storedAccount) {
    options.push({
      value: PaymentOptions.UseCreditCardOnFile,
      label: "Use credit card on file",
    });
  }
  if (paymentStepInfo.creditCardOverrideAvailable) {
    options.push({
      value: PaymentOptions.OverrideTerms,
      label: "Override terms & set credit card",
    });
  }
  options.push({
    value: PaymentOptions.PayWithNewCreditCard,
    label: "Pay with a new credit card",
  });

  return (
    <MarathonRadioField
      name={fieldName<PaymentData>("paymentOptions")}
      radioGroupProps={{ options }}
    />
  );
};
