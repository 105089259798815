import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";

interface SubmitAfterDelayProps {
  delay?: number;
}

export function SubmitAfterDelay({ delay = 200 }: SubmitAfterDelayProps) {
  const { submit } = useForm();
  const { pristine, active, values } = useFormState({ subscription: { pristine: true, values: true, active: true } });

  useEffect(() => {
    if (!pristine && active === undefined) {
      const timer = setTimeout(submit, delay);
      return () => clearTimeout(timer);
    }
  }, [pristine, values, delay, active]);

  return <></>;
}
