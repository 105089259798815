import React, { useRef } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MarathonAccordion } from "../../common/components/MarathonAccordion";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CustomerTypeSelector } from "./CustomerTypeSelector";
import Stack from "@mui/material/Stack";
import { Field, Form, FormRenderProps } from "react-final-form";
import { fieldName } from "../../forms/utils/fieldName";
import { isRequired } from "../../forms/validators/isRequired";
import { isEmail } from "../../forms/validators/isEmail";
import { composeValidators } from "../../forms/validators/composeValidators";
import { Invitee } from "../types/Invitee";
import { inviteNewUser } from "../api/inviteNewUser";
import Alert from "@mui/material/Alert";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { MarathonButton } from "../../common/components/MarathonButton";

type InviteByEmailProps = {
  onAddUserAccount: () => void;
};

export const InviteByEmail = ({ onAddUserAccount }: InviteByEmailProps) => {
  const formRef = useRef<FormRenderProps<Invitee>["form"]>();
  const { callApi, isLoading, isSuccessful, setIsSuccessful, errorMessage } = makeAsyncCall({
    additionalParameters: { form: formRef },
    apiMethod: inviteNewUser,
    onSuccess: (_response, additionalParams) => {
      additionalParams?.form?.current?.restart();
      onAddUserAccount();
    },
  });

  return (
    <>
      <MarathonAccordion>
        <AccordionSummary sx={{ "& .MuiAccordionSummary-content": { justifyContent: "flex-end" } }}>
          <Typography sx={{ textDecoration: "underline" }}>Invite new users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={4}>
            <Form<Invitee>
              onSubmit={(values, form) => {
                formRef.current = form;
                return callApi(values);
              }}
              render={({ handleSubmit }) => {
                return (
                  <>
                    <Field<Invitee["email"]>
                      name={fieldName<Invitee>("email")}
                      validate={composeValidators(isEmail, isRequired)}
                      render={({ input, meta }) => {
                        return (
                          <TextField
                            label={"Invite by email"}
                            type={"input"}
                            inputProps={input}
                            error={
                              (meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)
                            }
                            helperText={
                              (meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)
                                ? meta.error
                                : undefined
                            }
                            required
                          />
                        );
                      }}
                    />
                    <Field<Invitee["customerType"]>
                      name={fieldName<Invitee>("customerType")}
                      validate={isRequired}
                      render={({ input, meta }) => {
                        return (
                          <CustomerTypeSelector
                            inputProps={input}
                            error={(meta.dirty && meta.invalid) || meta.submitFailed}
                            helperText={
                              (meta.dirty && meta.invalid) || meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            required
                          />
                        );
                      }}
                    />
                    <MarathonButton
                      color={"secondary"}
                      isLoading={isLoading}
                      onClick={handleSubmit}
                      sx={{ maxWidth: { xl: "35%", xs: "100%" } }}
                    >
                      Invite User
                    </MarathonButton>
                    {isSuccessful && (
                      <Alert onClose={() => setIsSuccessful(null)}>Invite sent sucessfully</Alert>
                    )}
                    {isSuccessful === false && (
                      <Alert
                        severity={"warning"}
                        onClose={() => setIsSuccessful(null)}
                      >
                        {errorMessage}
                      </Alert>
                    )}
                  </>
                );
              }}
            />
          </Stack>
        </AccordionDetails>
      </MarathonAccordion>
    </>
  );
};
