import React from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";

type HoldMyOrderTogglesProps = {
  holdOrder: boolean;
  setHoldOrder: (value: boolean) => void;
};

export const HoldMyOrderToggle: React.FC<HoldMyOrderTogglesProps> = ({
  holdOrder,
  setHoldOrder,
}) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          checked={holdOrder}
          control={<Switch size={"medium"} />}
          label={
            <Typography
              variant={"button"}
              fontWeight={"bold"}
            >
              {holdOrder
                ? "Hold my order and ship when all items are complete"
                : "Ship now as partial order (back-ordered items will ship later)"}
            </Typography>
          }
          onChange={(event, checked) => setHoldOrder(checked)}
        />
      </FormGroup>
      <Typography
        variant="body1"
        className="copy"
        sx={{ pt: 2, lineHeight: 2 }}
      >
        We make partial shipments to the best of our abilities on out of stock and backordered
        products. Some items may have stock available in other branches. We will transfer and bring
        in material as soon as possible to fill your order. If you have questions about the
        available quantities or the estimated delivery date, please contact our customer service or
        your sales representative. <strong>We will only invoice for shipped items</strong>.
      </Typography>
    </>
  );
};
