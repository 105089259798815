import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import { useAsyncData } from "@torqit/torq-tools-react";
import { getAllAddresses } from "../../addresses/api/getAllAddresses";
import { InvoiceFilterBarLoader } from "./InvoiceFilterBarLoader";
import { MarathonSelectField } from "../../forms/components/MarathonSelectField";
import { InvoiceFilterForm } from "../types/InvoiceFilterForm";
import { fieldName } from "../../forms/utils/fieldName";
import MenuItem from "@mui/material/MenuItem";
import { DatePickerField } from "../../forms/components/DatePickerField";
import Typography from "@mui/material/Typography";

export type InvoiceFilterBarProps = {};

export const InvoiceFilterBarFields = ({}: InvoiceFilterBarProps) => {
  const { data: addresses, isLoading } = useAsyncData(getAllAddresses, []);
  const addressOptions = useMemo(
    () =>
      addresses
        ? [{ addressDefault: false, shipToId: 0, addressName: "ALL LOCATIONS" }, ...addresses]
        : null,
    [addresses]
  );

  return !isLoading && addressOptions !== null ? (
    <>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <MarathonSelectField
            name={fieldName<InvoiceFilterForm>("shipToId")}
            textFieldProps={{
              label: "Location",
              fullWidth: true,
            }}
          >
            {addressOptions.map((address) => (
              <MenuItem
                key={address.shipToId}
                value={address.shipToId}
              >
                <Typography display={"inline"}>
                  {address.addressName}
                  {address.addressLine1 ? " " : ""}
                  {address.addressLine1 ? (
                    <Typography
                      display={"inline"}
                      color={"grey"}
                    >
                      {"- " + address.addressLine1}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </MenuItem>
            ))}
          </MarathonSelectField>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
        >
          <MarathonSelectField
            name={fieldName<InvoiceFilterForm>("pagination.itemsPerPage")}
            textFieldProps={{ label: "Invoices per page", fullWidth: true }}
          >
            <MenuItem
              key={5}
              value={5}
            >
              5
            </MenuItem>
            <MenuItem
              key={10}
              value={10}
            >
              10
            </MenuItem>
            <MenuItem
              key={15}
              value={15}
            >
              15
            </MenuItem>
          </MarathonSelectField>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
        >
          <DatePickerField
            name={fieldName<InvoiceFilterForm>("startDate")}
            textFieldProps={{
              label: "Start Date",
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
        >
          <DatePickerField
            name={fieldName<InvoiceFilterForm>("endDate")}
            textFieldProps={{
              label: "End Date",
              fullWidth: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <InvoiceFilterBarLoader />
  );
};
