import React, { HTMLProps } from "react";

type TextButtonProps = Omit<HTMLProps<HTMLButtonElement>, "type">;

export const FakeTextButton = (props: TextButtonProps) => {
  return (
    <button
      type={"button"}
      style={{ textDecoration: "underline" }}
      {...props}
    >
      {props.children}
    </button>
  );
};
