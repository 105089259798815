import { sendGet, sendPost } from "@torqit/torq-tools-react";
import { CreateUserLogin } from "../types/CreateUserLogin";
import { Create2FACodeLogin } from "../types/Create2FACodeLogin";
import { ProviderResponse } from "../types/TwoFactor";

export async function createUserLogin(payload: CreateUserLogin) {
    return sendPost<CreateUserLogin, {loginSuccessful: boolean, twoFactorComplete: boolean}>("/api/login", payload);
}

export async function createUser2FACodeLogin(payload: Create2FACodeLogin) {
    return sendPost<Create2FACodeLogin, {}>("/2fa_check", payload);
}

export async function getProviders() {
    return sendGet<ProviderResponse>("/2fa/auth/get-available-providers");
}

export async function setPreferredProvider(preferredProvider: string) {
    return sendPost<{preferredProvider: string}, {}>("/2fa/auth/set-current-provider", {preferredProvider: preferredProvider});
}

export async function resendCode(twoFactorMethod: string) {
    return sendPost<{twoFactorMethod: string}, {success: boolean; message?: string}>("/resend-2fa-code", {twoFactorMethod: twoFactorMethod});
}

export async function sendBackupCode() {
    return sendPost<{},{success: boolean, message?: string}>("/2fa/send-backup-code");
}