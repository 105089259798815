import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { InvoiceFilterBarFields } from "./InvoiceFilterBarFields";
import { getInvoices } from "../api/getInvoices";
import { InvoiceFilterForm } from "../types/InvoiceFilterForm";
import dayjs from "dayjs";
import { MARATHON_DATE_FORMAT } from "../../forms/components/DatePickerField";
import { SubmitAutomatically } from "../../forms/components/SubmitAutomatically";
import { Form } from "react-final-form";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import Box from "@mui/material/Box";
import { InvoiceCard } from "./InvoiceCard";
import { ReturnCard } from "./ReturnCard";
import { SelectInvoiceReturnOptionField } from "./SelectInvoiceReturnOptionField";
import { InvoiceReturnOptions } from "../types/InvoiceReturnOptions";
import { StackedLoaders } from "../../common/components/StackedLoaders";
import { fieldName } from "../../forms/utils/fieldName";
import { ResetPageNumber } from "./ResetPageNumber";
import { InvoicePaginator } from "./InvoicePaginator";
import Stack from "@mui/material/Stack";

export type InvoicesProps = {};

export const Invoices = ({}: InvoicesProps) => {
  const { data, isLoading, callApi } = makeAsyncCall({ apiMethod: getInvoices });

  return (
    <Form<InvoiceFilterForm>
      keepDirtyOnReinitialize
      mutators={{
        resetPageNumber([], state, { changeValue }) {
          changeValue(state, fieldName<InvoiceFilterForm>("pagination.pageNumber"), () => 1);
        },
      }}
      onSubmit={(values) =>
        callApi(values).then(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }))
      }
      initialValues={{
        shipToId: 0,
        invoiceReturnOptions: InvoiceReturnOptions.Invoice,
        startDate: dayjs().format(MARATHON_DATE_FORMAT),
        endDate: dayjs().format(MARATHON_DATE_FORMAT),
        pagination: { pageNumber: 1, itemsPerPage: 5 },
      }}
      initialValuesEqual={() => true} // Stops form from thinking initialValues have changed
      render={() => (
        <>
          <ResetPageNumber />
          <SubmitAutomatically debounceTimeout={200} />

          <Box pb={40} /* padding to counteract poorly placed footer */>
            <Box pb={2}>
              <Typography variant={"h2"}>Invoices</Typography>
              <SelectInvoiceReturnOptionField
                name={fieldName<InvoiceFilterForm>("invoiceReturnOptions")}
              />
            </Box>
            <Box pb={2}>
              <InvoiceFilterBarFields />
            </Box>
            {!isLoading && data?.invoices ? (
              data.invoices.length > 0 ? (
                <>
                  <Stack
                    display={"flex"}
                    justifyContent={"center"}
                    gap={4}
                  >
                    {data.invoices.map((invoice) => (
                      <Fragment key={invoice.id}>
                        <InvoiceCard invoice={invoice} />
                      </Fragment>
                    ))}
                    <Box
                      py={2}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <InvoicePaginator count={data?.pagination.maxNumPages} />
                    </Box>
                  </Stack>
                </>
              ) : (
                <Typography>
                  Sorry, there are no invoices available with that criteria. Try changing your
                  shipping location and/or the starting or ending dates.
                </Typography>
              )
            ) : !isLoading && data?.rma ? (
              data.rma.length > 0 ? (
                <>
                  <Stack
                    display={"flex"}
                    justifyContent={"center"}
                    gap={4}
                  >
                    {data.rma.map((rma) => (
                      <Fragment key={rma.id}>
                        <ReturnCard rma={rma} />
                      </Fragment>
                    ))}
                    <Box
                      py={2}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <InvoicePaginator count={data?.pagination.maxNumPages} />
                    </Box>
                  </Stack>
                </>
              ) : (
                <Typography>
                  Sorry, there are no invoices available with that criteria. Try changing your
                  shipping location and/or the starting or ending dates.
                </Typography>
              )
            ) : (
              <StackedLoaders
                numLoaders={3}
                skeletonProps={{ height: 200 }}
              />
            )}
          </Box>
        </>
      )}
    />
  );
};
