import React, { ReactNode } from "react";
import { Icons, MarathonIcon } from "../../icons";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export type DisplayOrderOptionProps = {
  icon: Icons;
  label: ReactNode;
};

export const DisplayOrderOption = ({ label, icon }: DisplayOrderOptionProps) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      minHeight={80}
      maxWidth={400}
    >
      <MarathonIcon
        icon={icon}
        fontSize={"large"}
      />
      <Typography align={"left"}>{label}</Typography>
    </Stack>
  );
};
