import React, {Fragment, useState} from "react";
import {makeAsyncCall} from "../../common/hooks/makeAsyncCall";
import {getOpenOrders} from "../api/getOpenOrders";
import {Form} from "react-final-form";
import {OpenOrdersFilterForm} from "../types/OpenOrdersFilterForm";
import {SubmitAutomatically} from "../../forms/components/SubmitAutomatically";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {StackedLoaders} from "../../common/components/StackedLoaders";
import {OpenOrdersFilterBarFields} from "./OpenOrdersFilterBarFields";
import {OrderCard} from "./OrderCard";
import {SelectOrderOptionField} from "./SelectOrderOptionField";
import {OrderOptions} from "../types/OrderOptions";
import {fieldName} from "../../forms/utils/fieldName";
import dayjs from "dayjs";
import {MARATHON_DATE_FORMAT} from "../../forms/components/DatePickerField";
import {OrderPaginator} from "./OrderPaginator";

export const MyOrders: React.FC = () => {
  const { data, isLoading, callApi } = makeAsyncCall({ apiMethod: getOpenOrders });

  return (
    <Form<OpenOrdersFilterForm>
      keepDirtyOnReinitialize
      mutators={{
        resetPageNumber([], state, { changeValue }) {
          changeValue(state, fieldName<OpenOrdersFilterForm>("pagination.pageNumber"), () => 1);
        },
      }}
      onSubmit={(values) =>
        callApi(values).then(() => window.scrollTo({top: 0, left: 0, behavior: "smooth"}))
      }
      initialValues={{
        shipToId: 0,
        orderOptions: OrderOptions.OpenOrders,
        startDate: dayjs().format(MARATHON_DATE_FORMAT),
        endDate: dayjs().format(MARATHON_DATE_FORMAT),
        pagination: {pageNumber: 1, itemsPerPage: 5},
      }}
      initialValuesEqual={() => true} // Stops form from thinking initialValues have changed
      render={() => (
        <>
          <SubmitAutomatically debounceTimeout={200}/>

          <Box pb={40} /* padding to counteract poorly placed footer */>
            <Box pb={2}>
              <Typography variant={"h2"}>My Orders</Typography>
              <SelectOrderOptionField
                name={fieldName<OpenOrdersFilterForm>("orderOptions")}
              />
            </Box>
            <Box pb={2}>
              <OpenOrdersFilterBarFields
                option={data?.options.orderOptions}
              />
            </Box>
            {!isLoading && data?.orders ? (
              data.orders.length > 0 ? (
                <>
                  <Stack display={"flex"} justifyContent={"center"} gap={4}>
                    {data.orders.map((order) => (
                      <Fragment key={order.id}>
                        <OrderCard order={order}/>
                      </Fragment>
                    ))}
                    {data.options.orderOptions === "history" && (
                      <>
                        <Box py={2} display={"flex"} justifyContent={"center"}>
                          <OrderPaginator count={data?.pagination.maxNumPages} />
                        </Box>
                      </>
                    )}
                  </Stack>
                </>
              ) : (
                <Typography>
                  Sorry, there are no open order available with that criteria. Try changing your
                  shipping location.
                </Typography>
              )
            ) : (
              <StackedLoaders numLoaders={3} skeletonProps={{height: 200}}/>
            )}
          </Box>
        </>
      )}
    />
  );
};
