import React from "react";
import { Icons, MarathonIcon } from "../../../icons";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PaymentStep } from "../../types/Checkout";

export type CreditCardButtonProps = {
  paymentStepInfo: PaymentStep | null;
};

export const DisplayCreditCard = ({ paymentStepInfo }: CreditCardButtonProps) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <MarathonIcon
        icon={Icons.CreditCardGeneric}
        fontSize={"large"}
        sx={{ minWidth: 60, minHeight: 60 }}
      />
      <Stack>
        <Typography>Card ending in {paymentStepInfo?.storedAccount?.accountNumber}</Typography>
        <Typography>Expires: {paymentStepInfo?.storedAccount?.expirationDate}</Typography>
      </Stack>
    </Stack>
  );
};
