import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useConfirmationDialog } from "../../common/hooks/useConfirmationDialog";
import { useIsMobile } from "../../common/hooks/useIsMobile";
import { MarathonTextField } from "../../forms/components/MarathonTextField";
import { RequiredTextField } from "../../forms/components/RequiredTextField";
import { CartItemFormValues } from "../types/CartItemFormValues";
import { CART_ITEM_LABEL_WIDTH } from "./CartItemForm";
import IconButton from "@mui/material/IconButton";
import { useField, useFormState } from "react-final-form";
import { asFloat } from "../../forms/formatters/asFloat";
import type { Product } from "../types/Product";
import { useAsyncData } from "@torqit/torq-tools-react";
import { getTranslation } from "../../common/api/getTranslation";

type CartItemFieldsProps = {
  fieldNames: Record<keyof CartItemFormValues, string>;
  product?: Product;
  isSample?: boolean;
  inEditMode?: boolean;
  onRemove?: () => void;
  onFavourite?: () => void;
  isPriceUpdating?: boolean;
};

export const CartItemFields = ({
  fieldNames,
  isSample = false,
  inEditMode = false,
  onRemove,
  onFavourite,
  product,
  isPriceUpdating,
}: CartItemFieldsProps) => {
  const { submitting } = useFormState({ subscription: { submitting: true } });

  const {
    input: { value, onChange },
  } = useField(fieldNames.quantity);

  const minQty = useMemo(() => product?.unitOfMeasure.minQty ?? 1, [product?.unitOfMeasure.minQty]);

  const isMobile = useIsMobile();

  const { data: underMinQtyErrorMessage } = useAsyncData(
    () =>
      getTranslation("message_input_qty_base_val", {
        $baseValue: minQty,
        $baseValueDiff: minQty - Number(value),
      }),
    [],
    "message_input_qty_base_val"
  );

  const { data: wrongMultipleErrorMessage } = useAsyncData(
    () =>
      getTranslation("message_input_qty_multiplier", {
        $baseValue: minQty,
      }),
    [],
    "message_input_qty_multiplier"
  );

  const { setIsOpen, component } = useConfirmationDialog({
    title: "Confirm",
    children: <Box padding={3}>Are you sure you want to remove this item from your cart?</Box>,
    onConfirm: onRemove ?? (() => undefined),
  });

  const quantityInterval = useMemo(
    () => (minQty && product?.unitOfMeasure.asMultiplier ? minQty : 1),
    [minQty, product?.unitOfMeasure.asMultiplier]
  );

  function increaseQuantity() {
    const newValue = Number(value) + quantityInterval;
    onChange(newValue);
  }

  function decreaseQuantity() {
    const newValue = Number(value) - quantityInterval;
    onChange(newValue > 0 ? newValue : "0");
  }

  function validateQuantity(_value?: string) {
    const value = Number(_value);
    if (product?.unitOfMeasure.asMultiplier) {
      if (value < minQty) {
        return underMinQtyErrorMessage as string;
      } else if (value % minQty !== 0) {
        return wrongMultipleErrorMessage as string;
      }
    }
  }

  const quantityValidationMessage = useMemo(() => validateQuantity(value), [validateQuantity]);

  const areFieldsDisabled = useMemo(
    () => !inEditMode || isSample || submitting || isPriceUpdating,
    [inEditMode, isSample, submitting, isPriceUpdating]
  );

  const isSidebarOpen = useRef(false);
  useEffect(() => {
    const el = document.getElementById("favourite");
    const classWatcher = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          // @ts-ignore
          const currentlyOpen: boolean = mutation.target.classList.contains("is-open");
          if (isSidebarOpen.current !== currentlyOpen) {
            isSidebarOpen.current = currentlyOpen;
            if (!currentlyOpen) {
              onFavourite?.();
            }
          }
        }
      });
    });
    if (el) {
      classWatcher.observe(el, { attributes: true });
    }

    return () => classWatcher.disconnect();
  }, []);

  return (
    <>
      {component}
      <Stack direction={isMobile ? "column" : "row"} spacing={2} alignItems={isMobile ? "start" : "center"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Box width={CART_ITEM_LABEL_WIDTH}>
            <Typography fontSize={"18px"}>Quantity:</Typography>
          </Box>
          <Stack direction={"row"} alignItems={"center"}>
            {inEditMode && (
              <button className="quantity-changer qty-mins" onClick={decreaseQuantity} disabled={areFieldsDisabled}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  width="50"
                  height="50"
                  className="svg replaced-svg"
                  viewBox="0 0 50 50"
                >
                  <path d="M9 23v4h32v-4z"></path>
                </svg>
              </button>
            )}
            <RequiredTextField
              name={fieldNames.quantity}
              textFieldProps={{
                required: false,
                disabled: areFieldsDisabled,
                sx: { width: 86 },
                size: "small",
                InputProps: { sx: { bgcolor: "white", fontWeight: "bold" } },
              }}
              validators={[validateQuantity]}
              fieldProps={{ format: asFloat }}
              inputProps={{ maxLength: 5, style: { textAlign: "center" } }}
              hideHelperText
            />
            {inEditMode && (
              <button className="quantity-changer qty-plus" onClick={increaseQuantity} disabled={areFieldsDisabled}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  width="50"
                  height="50"
                  className="svg replaced-svg"
                  viewBox="0 0 50 50"
                >
                  <path d="M23 9v14H9v4h14v14h4V27h14v-4H27V9z"></path>
                </svg>
              </button>
            )}
          </Stack>
        </Stack>
        {inEditMode && (
          <Stack
            direction={"row"}
            spacing={1}
            display={"flex"}
            justifyContent={"space-evenly"}
            width={isMobile ? "100%" : undefined}
          >
            <IconButton size={"small"} color={"secondary"} onClick={() => setIsOpen(true)} disableRipple>
              <img src={"/static/cdboxV2/images/icon-trash.svg"} alt={"remove"} width={28} height={24} />
            </IconButton>
            <IconButton
              disableRipple
              className={"top-nav-favorite"}
              data-toggle={"favourite"} // ties to existing candybox function
              id={`fav-${product?.id}`}
              style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}
              onClick={() => {
                document
                  .getElementById("selected-favorite-product-id")
                  ?.setAttribute("value", product?.id?.toFixed() ?? "0");
              }}
            >
              <svg
                width={"28"}
                height={"24"}
                viewBox={"0 0 28 24"}
                fill={product?.isFavorited ? "#DA291C" : "none"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d={
                    "M8.30065 1.33338C4.45315 1.33338 1.33398 4.39422 1.33398 8.16979C1.33398 15.0062 9.56732 21.2211 14.0007 22.6667C18.434 21.2211 26.6673 15.0062 26.6673 8.16979C26.6673 4.39422 23.5482 1.33338 19.7007 1.33338C17.3447 1.33338 15.261 2.48127 14.0007 4.23823C13.3582 3.3403 12.5048 2.60749 11.5126 2.10184C10.5204 1.59619 9.41867 1.3326 8.30065 1.33338Z"
                  }
                  stroke={product?.isFavorited ? "#DA291C" : "#2D2926"}
                  strokeWidth={"1.5"}
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  style={{ transition: "none" }}
                />
              </svg>
            </IconButton>
          </Stack>
        )}
      </Stack>
      {quantityValidationMessage !== undefined && <Typography color={"red"}>{quantityValidationMessage}</Typography>}
      {inEditMode && (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Box width={CART_ITEM_LABEL_WIDTH}>
            <Typography fontSize={"18px"}>Tag:</Typography>
          </Box>
          <MarathonTextField
            name={fieldNames.notes}
            textFieldProps={{
              size: "small",
              InputProps: { sx: { bgcolor: "white" } },
              disabled: submitting || isPriceUpdating || !inEditMode,
            }}
          />
        </Stack>
      )}
    </>
  );
};
