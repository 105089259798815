import Box from "@mui/material/Box";
import React from "react";
import { useIsMobile } from "../../common/hooks/useIsMobile";

type CartItemPictureProps = {
  imageUrl?: string;
  productUrl?: string;
  alt?: string;
};

export const CartItemPicture = ({ imageUrl, productUrl, alt }: CartItemPictureProps) => {
  return (
    <>
      <Box minWidth={130} minHeight={130} maxWidth={130} maxHeight={130}>
        <a href={productUrl}>
          <img src={imageUrl} alt={alt} loading="lazy" width={"100%"} />
        </a>
      </Box>
    </>
  );
};
