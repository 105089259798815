import React from "react";
import { CustomerType } from "../types/CustomerType";
import MenuItem from "@mui/material/MenuItem";
import { capitalizeWord } from "../../common/utils/capitalizeWord";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { customerTypeDescriptions } from "../utils/customerTypeDescriptions";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type CustomerTypeSelectorProps = { value?: CustomerType } & Omit<TextFieldProps, "value" | "label">;

export const CustomerTypeSelector = (props: CustomerTypeSelectorProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TextField
      fullWidth
      select
      label={"Customer Type"}
      {...props}
    >
      {Object.values(CustomerType).map((value) => (
        <MenuItem
          key={value}
          value={value}
        >
          {
            <span>
              <Typography display={"inline"}>{capitalizeWord(value)}</Typography>
              {!isMobile && (
                <Typography
                  display={"inline"}
                >{` - ${customerTypeDescriptions[value]}`}</Typography>
              )}
            </span>
          }
        </MenuItem>
      ))}
    </TextField>
  );
};
