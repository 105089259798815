import { sendGet, sendPost } from "@torqit/torq-tools-react";
import { Invoice } from "../types/Invoice";
import { InvoiceFilterForm } from "../types/InvoiceFilterForm";
import { InvoiceReturnOptionsSelect } from "../types/InvoiceReturnOptions";
import { Pagination } from "../../common/types/Pagination";
import { Return } from "../types/Return";

type Payload = { invoices: Invoice[], rma: Return[], options: InvoiceReturnOptionsSelect, pagination: Pagination };

export async function getInvoices(payload: InvoiceFilterForm) {
    return sendPost<InvoiceFilterForm, Payload>("/account/get-invoices", payload);
}
