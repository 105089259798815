import React from "react";
import { ColoredIconPaths, ColoredIcons } from "../Icons";
import Icon from "@mui/material/Icon";
import { camelCaseToText } from "../../common/utils/camelCaseToText";

export type ColoredMarathonIconProps = {
  icon: ColoredIcons;
};

export const ColoredMarathonIcon = ({ icon }: ColoredMarathonIconProps) => {
  return (
    <Icon fontSize={"large"}>
      <img
        src={ColoredIconPaths[icon]}
        alt={camelCaseToText(icon)}
      />
    </Icon>
  );
};
