import React from "react";
import { States } from "../States";
import { EnumSelector, EnumSelectorProps } from "../../forms/components/EnumSelector";

type StateSelectorProps = Omit<EnumSelectorProps, "options">;

export const StateSelector = (props: StateSelectorProps) => {
  return (
    <EnumSelector
      {...props}
      options={States}
    />
  );
};
