import { useState } from "react";
import { Order } from "../types/Order";
import { ConfirmOrderParams } from "../types/ConfirmOrderParams";
import { toast } from "react-toastify";
import { CheckoutSteps } from "../Checkout";
import { UseCart } from "./useCart";
import { ConfirmOrderResponse } from "../types/ConfirmOrderResponse";
import { confirmOrder } from "../api/confirmOrder";
import type { Cart } from "../types/Cart";
import type { Product } from "../types/Product";

export type UseConfirmOrder = {
    order: ConfirmOrderResponse["order"] | null;
    setOrder: (order: ConfirmOrderResponse["order"]) => void;
    isOrderLoading: boolean;
    setIsOrderLoading: (isLoading: boolean) => void;
    runConfirmOrder: (params: ConfirmOrderParams) => Promise<void>;
    tokenWithPrice: ConfirmOrderResponse["tokenWithPrice"] | null;
    setTokenWithPrice: (token: ConfirmOrderResponse["tokenWithPrice"]) => void;
    tokenNoPrice: ConfirmOrderResponse["tokenNoPrice"] | null;
    setTokenNoPrice: (token: ConfirmOrderResponse["tokenNoPrice"]) => void;
};

function pushOrderToGTM(cart: Cart, order: Order) {
    const items = [
        ...cart.products,
        ...cart.accessoryProducts,
        ...cart.backOrderedProducts,
        ...cart.sampleProducts,
        ...cart.specialOrderProducts,
    ].map((product: Product) => ({
        item_id: product.partNumber,
        item_name: product.name,
        item_category: product.groupDescription,
        affiliation: "Web Store",
        currency: "CAD",
        item_brand: product.brandName,
        price: product.pricePerUnit,
        quantity: product.quantity,
    }));

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            currency: "CAD",
            transaction_id: order.webReference,
            value: Number(cart.orderTotal),
            affiliation: "Web Store",
            shipping: Number(cart.shippingCost),
            tax: Number(cart?.estimatedSalesTax),
            items,
        },
    });
}

export function useConfirmOrder(
    { setCart, setIsCartLoading }: Pick<UseCart, "setCart" | "setIsCartLoading">,
    setCheckoutStep: (checkoutStep: CheckoutSteps) => void
): UseConfirmOrder {
    const [order, setOrder] = useState<Order | null>(null);
    const [isOrderLoading, setIsOrderLoading] = useState(false);
    const [tokenWithPrice, setTokenWithPrice] = useState<ConfirmOrderResponse["tokenWithPrice"] | null>(null);
    const [tokenNoPrice, setTokenNoPrice] = useState<ConfirmOrderResponse["tokenNoPrice"] | null>(null);

    const runConfirmOrder = async (params: ConfirmOrderParams) => {
        setIsOrderLoading(true);
        try {
            const { order, cart, tokenWithPrice, tokenNoPrice } = await confirmOrder(params);
            pushOrderToGTM(cart, order);
            setOrder(order);
            setCart(cart);
            setTokenWithPrice(tokenWithPrice);
            setTokenNoPrice(tokenNoPrice);
            setCheckoutStep(CheckoutSteps.Confirmed);
        } catch (e: any) {
            if (e instanceof Error) {
                toast.error(e.message);
            }
        }
        setIsOrderLoading(false);
    };

    return {
        order,
        setOrder,
        isOrderLoading,
        setIsOrderLoading,
        runConfirmOrder,
        tokenWithPrice,
        setTokenWithPrice,
        tokenNoPrice,
        setTokenNoPrice,
    };
}
