import { useState } from "react";
import { updateUser } from "../api/updateUser";
import { deleteUser } from "../api/deleteUser";
import { User } from "../types/User";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";

export function useAccountEditor(userAccount: User, updateUserAccount: () => void) {
    const [customerType, setCustomerType] = useState(userAccount.customerType);

    const {
        isLoading: updateIsLoading,
        setIsLoading: setUpdateIsLoading,
        callApi: update,
        isSuccessful: isUpdateSuccessful,
        setIsSuccessful: setIsUpdateSuccessful,
        errorMessage: updateErrorMessage,
    } = makeAsyncCall({
        apiMethod: () => updateUser({ id: userAccount.id, customerType }),
        onSuccess: updateUserAccount,
    });

    const {
        isLoading: deleteIsLoading,
        setIsLoading: setDeleteIsLoading,
        isSuccessful: isDeleteSuccessful,
        errorMessage: deleteErrorMessage,
        setIsSuccessful: setIsDeleteSuccessful,
        callApi: runDelete,
    } = makeAsyncCall({
        apiMethod: () => deleteUser(userAccount.id),
        onSuccess: updateUserAccount,
    });

    return {
        customerType,
        setCustomerType,
        updateIsLoading,
        setUpdateIsLoading,
        isUpdateSuccessful,
        setIsUpdateSuccessful,
        updateErrorMessage,
        deleteIsLoading,
        setDeleteIsLoading,
        update,
        runDelete,
        isDeleteSuccessful,
        deleteErrorMessage,
        setIsDeleteSuccessful,
    };
}
