// noinspection ES6UnusedImports
import {} from "@mui/x-date-pickers/themeAugmentation";
import { createTheme } from "@mui/material";
import { CustomDatePickerHeader } from "../components/CustomDatePickerHeader";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import React, { ElementType } from "react";

export const theme = createTheme({
  typography: {
    fontFamily: '"Hurme Geometric Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 18,
    h2: {
      fontSize: 48,
    },
  },
  palette: {
    primary: {
      main: "#da291c", // Marathon logo color
      dark: "#da291c",
    },
    secondary: {
      main: "#2d2926", // color of candybox buttons and such
    },
    success: {
      main: "#5ebd56",
    },
    error: {
      main: "#ff0000",
    },
    warning: {
      main: "#f39134",
    },
    info: {
      main: "#0038ff",
    },
    background: {
      paper: "#f9f9f9",
      default: "#f9f9f9",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        views: ["year", "month", "day"],
        slots: {
          calendarHeader: CustomDatePickerHeader as ElementType<
            PickersCalendarHeaderProps<unknown>
          >,
          openPickerIcon: (props) => (
            <CalendarMonthOutlinedIcon
              {...props}
              color={"secondary"}
            />
          ),
        },
      },
    },
  },
});
