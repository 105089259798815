import React from "react";
import { Provinces } from "../Provinces";
import { EnumSelector, EnumSelectorProps } from "../../forms/components/EnumSelector";

type ProvinceSelectorProps = Omit<EnumSelectorProps, "options">;

export const ProvinceSelector = (props: ProvinceSelectorProps) => {
  return (
    <EnumSelector
      {...props}
      options={Provinces}
    />
  );
};
