import React from "react";
import { Order } from "../types/Order";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {OrderItemTitle} from "./OrderItemTitle";

export type OrderCardProps = {
    order: Order;
};

export const OrderCard = ({ order }: OrderCardProps) => {
    const { user } = useCurrentUser();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box p={2} borderRadius={6} display={"flex"} position={"relative"} bgcolor={theme.palette.background.default}>
            <Box borderRadius={1} p={4} sx={{ flex: 1 }}>
                <Grid container>
                    <Grid container item xs={12} lg={10} spacing={2}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>Date</OrderItemTitle>
                                <Typography>{order.date}</Typography>{/* {order.backordersExist} {order.specialsExist}*/}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>Order Number</OrderItemTitle>
                                <Typography fontWeight={"bold"}>{order.id}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>PO #</OrderItemTitle>
                                <Typography>{order.poNumber}</Typography>
                            </Grid>
                        </Grid>
                        {!isMobile && (
                            <Grid item xs={12}>
                                <Divider sx={{ maxWidth: "98%" }} />
                            </Grid>
                        )}
                        <Grid container item xs={12}>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>Source</OrderItemTitle>
                                <Typography>{order.source}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>Shipping To</OrderItemTitle>
                                <Typography>{order.shippingTo}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <OrderItemTitle>Delivery Method</OrderItemTitle>
                                <Typography>{order.deliveryMethod}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={2} alignItems={"center"}>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                            <Tooltip title={"View Order"} placement={"top"}>
                                <IconButton sx={{ display: "flex", justifyContent: "center" }} onClick={() => window.open(`/action/account/order-detail/print?orderNumber=${order.id}`)}>
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <a style={{ display: "flex", justifyContent: "center" }} download href={`/action/account/order-detail/print?orderNumber=${order.id}`}>
                                <Tooltip title={"Download Order"} placement={"top"}>
                                    <IconButton>
                                        <SystemUpdateAltOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
