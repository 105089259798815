import { CircularProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { MarathonButton } from "../../../common/components/MarathonButton";
import { reloadCurrentUser } from "../../../common/hooks/CurrentUserProvider";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { MarathonCheckbox } from "../../../forms/components/MarathonCheckbox";
import { RequiredTextField } from "../../../forms/components/RequiredTextField";
import { fieldName } from "../../../forms/utils/fieldName";
import { createUserLogin } from "../../api/userLogin";
import { CreateUserLogin } from "../../types/CreateUserLogin";

export const LoginForm = ({}: {}) => {
  const initialState = useMemo<Partial<CreateUserLogin>>(
    () => ({
      rememberEmail: true,
      username: localStorage.getItem("rememberedEmail") ?? "",
    }),
    []
  );

  const reloadUser = reloadCurrentUser();

  const { callApi, isLoading, isSuccessful } = makeAsyncCall({
    apiMethod: createUserLogin,
    onSuccess: (response) => (response.twoFactorComplete ? routeToHome() : reloadUser()),
    onError: () => toast.error("Invalid login", { position: "top-left" }),
  });

  function onSubmit(values: CreateUserLogin) {
    if (values.rememberEmail) {
      localStorage.setItem("rememberedEmail", values.username);
    } else {
      localStorage.removeItem("rememberedEmail");
    }
    return callApi(values);
  }

  function routeToHome() {
    document.cookie = "pimcore_admin_sid=true";
    window.location.reload();
  }

  return (
    <>
      <h2>Welcome back.</h2>
      {isSuccessful ? (
        <CircularProgress />
      ) : (
        <Form<CreateUserLogin>
          onSubmit={onSubmit}
          initialValues={initialState}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} justifyContent={"center"}>
                <RequiredTextField name={fieldName<CreateUserLogin>("username")} textFieldProps={{ label: "Email" }} />
                <RequiredTextField
                  name={fieldName<CreateUserLogin>("password")}
                  textFieldProps={{ label: "Password", type: "password" }}
                />
                <Box p={0}>
                  <MarathonCheckbox name={fieldName<CreateUserLogin>("rememberEmail")} label={"Remember Email"} />
                </Box>
                <MarathonButton color={"secondary"} type="submit" isLoading={isLoading}>
                  <span>Login</span>
                </MarathonButton>
                <a href={"/action/secure/forgot"}>Forgot password</a>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
