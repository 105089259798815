import React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type StackedLoadersProps = {
  numLoaders: number;
  skeletonProps?: SkeletonProps;
};

export const StackedLoaders = ({ numLoaders, skeletonProps }: StackedLoadersProps) => {
  return (
    <Stack padding={2}>
      {[...Array(numLoaders).keys()].map((value) => (
        <Skeleton
          key={`loader-${value}`}
          {...skeletonProps}
        />
      ))}
    </Stack>
  );
};
