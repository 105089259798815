import { sendPost } from "@torqit/torq-tools-react";
import { PaymentFormData } from "../types/PaymentFormData";
import { PaymentInformationSave } from "../../types/Checkout";

export type PaymentData = {
    paymentOptions: string;
} & Partial<PaymentFormData>;

export type SendPaymentDataResponse = {
    errorMessage?: string;
    success: boolean;
    redirectTo?: string;
};

export async function savePaymentData(paymentData: PaymentData) {
    return await sendPost<PaymentInformationSave, SendPaymentDataResponse>(
        "/checkout/save-payment-information",
        paymentData
    );
}
