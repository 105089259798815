import React from "react";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Icons, MarathonIcon } from "../../icons";
import { Address } from "../types/Checkout";

export type DisplayAddressProps = {
  address?: Address | null;
  includeIcon?: boolean;
  icon?: Icons;
};

export const DisplayAddress: React.FC<DisplayAddressProps> = ({
  address,
  includeIcon = true,
  icon = Icons.Shipping,
}) => {
  const format = (value?: string | null) => value ?? "";

  const props: TypographyProps = { fontSize: "large", fontWeight: "bolder" };

  return (
    <Stack>
      <Typography
        {...props}
        color={"grey"}
      >
        {format(address?.addressName)}
      </Typography>
      <Typography {...props}>{format(address?.addressLine1)}</Typography>
      <Typography
        {...props}
        fontSize={"medium"}
      >
        {format(address?.addressLine2)}
      </Typography>
      <Typography {...props}>{format(address?.addressCity)}</Typography>
      <Typography {...props}>
        {format(address?.addressProvince?.toUpperCase()) +
          (address?.addressProvince && address?.addressCountry ? ", " : "") +
          format(address?.addressCountry?.toUpperCase())}
      </Typography>
      <Typography {...props}>{format(address?.addressPostalCode)}</Typography>
      {includeIcon && <MarathonIcon icon={icon} />}
    </Stack>
  );
};
