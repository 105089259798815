/*
 * To add a new icon. do these three things:
 * 1. Add the image file to the icons/images directory
 * 2. Add a new entry to the Icons enum
 * 3. Add a new entry to the IconPaths object where the key is the enum and the value is the imported image
 * */

import InvoiceWithPrice from "./images/print-with-price.svg";
import InvoiceNoPrice from "./images/printer-without-price.svg";
import Heart from "./images/icon-save-later.svg";
import Shipping from "./images/icon-shipping.svg?react";
import DefaultLocation from "./images/icon-default-location.svg?react";
import CreditCardGeneric from "./images/cc-generic.svg?react";
import CheckoutDelivery from "./images/icon-checkout-deliver.svg?react";
import PickUp from "./images/icon-pick-up.svg?react";
import Trash from "./images/icon-trash.svg?react";
import Deliver from "./images/icon-deliver.svg?react";
import OrderHistory from "./images/icon-order-history.svg?react";
import Invoice from "./images/icon-account-invoices.svg?react";
import Return from "./images/icon-account-claims.svg?react";
import Visa from "./images/visa-icon.svg?react";
import Mastercard from "./images/mastercard-icon.svg?react";
import Amex from "./images/amex-icon.svg?react";

export enum Icons {
    Shipping = "Shipping",
    DefaultLocation = "DefaultLocation",
    CreditCardGeneric = "CreditCardGeneric",
    CheckoutDelivery = "CheckoutDelivery",
    PickUp = "PickUp",
    Trash = "Trash",
    Deliver = "Deliver",
    OrderHistory = "OrderHistory",
    Invoice = "Invoice",
    Return = "Return",
    Visa = "Visa",
    Mastercard = "Mastercard",
    Amex = "Amex",
}

export enum ColoredIcons {
    InvoiceWithPrice = "InvoiceWithPrice",
    InvoiceNoPrice = "InvoiceNoPrice",
    Heart = "Heart",
}

export const IconPaths = {
    [Icons.Shipping]: Shipping,
    [Icons.DefaultLocation]: DefaultLocation,
    [Icons.CreditCardGeneric]: CreditCardGeneric,
    [Icons.CheckoutDelivery]: CheckoutDelivery,
    [Icons.PickUp]: PickUp,
    [Icons.Trash]: Trash,
    [Icons.Deliver]: Deliver,
    [Icons.OrderHistory]: OrderHistory,
    [Icons.Invoice]: Invoice,
    [Icons.Return]: Return,
    [Icons.Visa]: Visa,
    [Icons.Mastercard]: Mastercard,
    [Icons.Amex]: Amex,
};

export const ColoredIconPaths = {
    [ColoredIcons.InvoiceWithPrice]: InvoiceWithPrice,
    [ColoredIcons.InvoiceNoPrice]: InvoiceNoPrice,
    [ColoredIcons.Heart]: Heart,
};
