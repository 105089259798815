import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

export type InvoiceItemTitleProps = TypographyProps;

export const InvoiceItemTitle = (props: InvoiceItemTitleProps) => {
  return (
    <Typography
      {...props}
      fontSize={16}
      fontWeight={"bold"}
      color={"grey"}
    />
  );
};
