import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useMemo } from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { removeFromCart } from "../../cart/api/removeFromCart";
import { updateCart } from "../../cart/api/updateCart";
import { useIsMobile } from "../../common/hooks/useIsMobile";
import { fieldNames } from "../../forms/utils/fieldNames";
import { usePrice } from "../hooks/usePrice";
import { CartItemFormValues } from "../types/CartItemFormValues";
import { Product } from "../types/Product";
import { CartItemFields } from "./CartItemFields";
import { CartItemInformation } from "./CartItemInformation";
import { CartItemPicture } from "./CartItemPicture";
import { SubmitAfterDelay } from "../../forms/components/SubmitAfterDelay";

export const CART_ITEM_LABEL_WIDTH = 96;

const SUBMISSION_DELAY = 1000;

type CartItemFormProps = {
  product: Product;
  isPriceUpdating?: boolean;
  onUpdate?: () => void;
  inEditMode?: boolean;
  currencySymbol?: string;
};

export const CartItemForm = ({
  product,
  inEditMode = false,
  currencySymbol = "$",
  onUpdate,
  isPriceUpdating,
}: CartItemFormProps) => {
  const isMobile = useIsMobile();
  const totalPrice = usePrice(currencySymbol, product.totalPrice);

  async function onSubmit({ notes, quantity }: CartItemFormValues) {
    try {
      await updateCart({ id: product.id, notes, quantity });
      onUpdate?.();
    } catch (e) {
      toast.error("Encountered error while updating cart. Please try again later.");
    }
  }

  async function handleRemove() {
    try {
      await removeFromCart({ id: product.id });
      onUpdate?.();
    } catch (e) {
      toast.error("Encountered error while removing item from cart. Please try again later.");
    }
  }

  const totalPriceComponent = useMemo(
    () => (
      <Box display={"flex"} justifyContent={"flex-end"} pr={4}>
        <Typography fontWeight={"bold"}>{totalPrice}</Typography>
      </Box>
    ),
    [totalPrice]
  );

  return (
    <>
      <Form<CartItemFormValues>
        initialValues={{ quantity: product.quantity, notes: product.notes }}
        onSubmit={onSubmit}
        render={() => (
          <>
            {inEditMode && <SubmitAfterDelay delay={SUBMISSION_DELAY} />}
            <Stack direction={isMobile ? "column" : "row"} spacing={1}>
              <Box
                pr={{ xs: 0, md: 2, xl: 4 }}
                display={"flex"}
                justifyContent={isMobile ? "center" : undefined}
                alignItems={"center"}
              >
                <CartItemPicture imageUrl={product.imageUrl} alt={product.name} productUrl={product.productUrl} />
              </Box>
              {isMobile && totalPriceComponent}
              <Stack width={"100%"} spacing={1}>
                <Stack direction={isMobile ? "column-reverse" : "row"} justifyContent={"space-between"}>
                  <CartItemInformation product={product} currencySymbol={currencySymbol} />
                  {!isMobile && totalPriceComponent}
                </Stack>
                <CartItemFields
                  product={product}
                  fieldNames={fieldNames<CartItemFormValues, CartItemFormValues>({
                    notes: "notes",
                    quantity: "quantity",
                  })}
                  inEditMode={inEditMode && !product.isAccessory}
                  isSample={product.isSample}
                  onRemove={handleRemove}
                  onFavourite={onUpdate}
                  isPriceUpdating={isPriceUpdating}
                />
              </Stack>
            </Stack>
          </>
        )}
      />
    </>
  );
};
