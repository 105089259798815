import React, { Fragment, ReactElement } from "react";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { StackProps } from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export type MarathonRadioGroupProps = RadioGroupProps & {
  options: RadioOption[];
  direction?: StackProps["direction"];
  control?: ReactElement;
  formControlLabelProps?: Omit<FormControlLabelProps, "label" | "value" | "control">;
};

export type RadioOption = {
  label: React.ReactNode;
  value: string;
};

export function MarathonRadioGroup({
  options,
  formControlLabelProps,
  control = <Radio />,
  direction = "column",
  ...props
}: MarathonRadioGroupProps) {
  return (
    <>
      <RadioGroup {...props}>
        <Grid
          container
          justifyContent={"space-evenly"}
          gap={2}
        >
          {options.map(({ label, value }) => (
            <Fragment key={value}>
              <Grid
                item
                xs={direction === "row" ? "auto" : 12}
              >
                <FormControlLabel
                  sx={{ marginRight: 0, marginLeft: 0 }} // https://github.com/mui/material-ui/issues/34039
                  control={control}
                  value={value}
                  label={label}
                  {...formControlLabelProps}
                />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </RadioGroup>
    </>
  );
}
