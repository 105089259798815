import { Leaves } from "../types/Leaves";

// @ts-ignore
type Names<Source extends object, Target extends Object> = Record<Leaves<Source>, Leaves<Target>>;

/**
 * @param names object mapping from the properties of type `Source` (i.e. the names of the fields)
 * to the properties of type `Target` (i.e. the properties of the form object)
 * */
export function fieldNames<Source extends object, Target extends object>(
    names: Names<Source, Target>
): Names<Source, Target> {
    return names;
}
