import React, { Fragment } from "react";
import { Product } from "../types/Product";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MarathonAccordion } from "../../common/components/MarathonAccordion";
import { CartItemForm } from "./CartItemForm";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { CartItemSkeleton } from "./CartItemSkeleton";
import Typography from "@mui/material/Typography";

type ProductListProps = {
  products: Product[];
  isPriceUpdating?: boolean;
  onProductUpdate?: () => void;
  currencySymbol?: string;
  inEditMode?: boolean;
};

export const ProductList = ({
  products,
  isPriceUpdating,
  onProductUpdate,
  currencySymbol,
  inEditMode,
}: ProductListProps) => {
  return (
    <Stack spacing={2} divider={<Divider />}>
      {products.map((product) => (
        <Fragment key={`productList_${product.id}`}>
          <Box width={"100%"} pb={2}>
            <CartItemForm
              product={product}
              isPriceUpdating={isPriceUpdating}
              currencySymbol={currencySymbol}
              inEditMode={inEditMode}
              onUpdate={onProductUpdate}
            />
          </Box>
        </Fragment>
      ))}
    </Stack>
  );
};
