import React from "react";
import { OrderOptions } from "../types/OrderOptions";
import { Icons } from "../../icons";
import { HollowButtonRadioField } from "../../forms/components/HollowButtonRadioField";
import { RadioOption } from "../../common/components/MarathonRadioGroup";
import { DisplayOrderOption } from "./DisplayOrderOption";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type SelectOrderOptionFieldProps = {
  name: string;
};

export const SelectOrderOptionField = ({ name }: SelectOrderOptionFieldProps) => {
  const theme = useTheme();

  const options: RadioOption[] = [
    {
      value: OrderOptions.OpenOrders,
      label: (
        <DisplayOrderOption
          icon={Icons.Deliver}
          label={"Open Orders"}
        />
      ),
    },
    {
      value: OrderOptions.OrderHistory,
      label: (
        <DisplayOrderOption
          icon={Icons.OrderHistory}
          label={"Order History"}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <HollowButtonRadioField
          name={name}
          radioGroupProps={{
            options,
            direction: useMediaQuery(theme.breakpoints.down("lg")) ? "column" : "row",
          }}
        />
      </div>
    </>
  );
};
