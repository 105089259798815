import React from "react";
import { AccountEntry } from "./AccountEntry";
import { MarathonCard } from "../../common/components/MarathonCard";
import { InviteByEmail } from "./InviteByEmail";
import { useAsyncData } from "@torqit/torq-tools-react";
import { getUserAccounts } from "../api/getUserAccounts";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useMarathonPagination } from "../../common/hooks/useMarathonPagination";
import { sortUserAccounts } from "../utils/sortUserAccounts";
import { StackedLoaders } from "../../common/components/StackedLoaders";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { CustomerType } from "../types/CustomerType";

const ENTRY_MIN_HEIGHT = 100;

type UserAccountsProps = {};

export const UserAccounts = ({}: UserAccountsProps) => {
  const { user } = useCurrentUser();
  const isPrivileged = user?.customerType === CustomerType.Admin;
  const { data: userAccounts, isLoading, executeRequest } = useAsyncData(getUserAccounts, []);
  const { itemsBeingViewed, numLoadersPerPage, paginator } = useMarathonPagination({
    items: userAccounts?.sort(sortUserAccounts) ?? [],
  });

  return isPrivileged ? (
    <>
      <MarathonCard>
        <h3>User Accounts</h3>
        <Stack>
          {isLoading ? (
            <StackedLoaders
              numLoaders={numLoadersPerPage}
              skeletonProps={{ height: ENTRY_MIN_HEIGHT + 50 }}
            />
          ) : (
            itemsBeingViewed.map((userAccount, index) => (
              <React.Fragment
                key={`${userAccount.firstName}-${userAccount.lastName}-${userAccount.email}-${index}-entry`}
              >
                <AccountEntry
                  userAccount={userAccount}
                  onUpdateUserAccount={executeRequest}
                  accordionProps={{ sx: { minHeight: ENTRY_MIN_HEIGHT } }}
                />
                {itemsBeingViewed.length > index + 1 && <hr />}
              </React.Fragment>
            ))
          )}
          <Box
            paddingTop={4}
            display={"flex"}
            justifyContent={"center"}
          >
            {paginator}
          </Box>
          <hr />
          <InviteByEmail onAddUserAccount={executeRequest} />
        </Stack>
      </MarathonCard>
    </>
  ) : (
    <></>
  );
};
