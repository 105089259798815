import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { RESPONSE_FROM_WORLDPAY } from "./SendCreditCardSubmissionMessage";
import { useForm } from "react-final-form";
import { Icons, MarathonIcon } from "../../../icons";
import Stack from "@mui/material/Stack";

export type TransactionDataFieldsProps = {
  worldPayUrl: string;
  onFormDataSet: () => void;
};

export const TransactionDataFields = ({
  worldPayUrl,
  onFormDataSet,
}: TransactionDataFieldsProps) => {
  const { mutators, submit } = useForm();

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      if (ev.data.type === RESPONSE_FROM_WORLDPAY) {
        mutators.setFormAttribute("transactionData", ev.data.response);
        onFormDataSet();
      }
    });
  }, []);

  return (
    <>
      <div className="inner-frame">
        <Stack
          justifyContent={"left"}
          alignContent={"center"}
          direction={"row"}
          gap={2}
        >
          <MarathonIcon
            icon={Icons.Visa}
            sx={{ minWidth: 70, minHeight: 70 }}
          />
          <MarathonIcon
            icon={Icons.Mastercard}
            sx={{ minWidth: 70, minHeight: 70 }}
          />
          <MarathonIcon
            icon={Icons.Amex}
            sx={{ minWidth: 70, minHeight: 70 }}
          />
        </Stack>
        <iframe
          style={{ height: "300px", width: "100%", overflow: "hidden", border: "0" }}
          src={worldPayUrl}
          scrolling={"no"}
        ></iframe>
      </div>
    </>
  );
};
