import { sendPost } from "@torqit/torq-tools-react";
import { CartItemFormValues } from "../../checkout/types/CartItemFormValues";
import { Product } from "../../checkout/types/Product";

type Payload = CartItemFormValues & {
    id: Product["id"];
    isSample?: boolean;
};

export async function updateCart(payload: Payload) {
    return sendPost<Payload, undefined>("/action/cart/update-cart", payload);
}
