import { useAsyncData } from "@torqit/torq-tools-react";
import { useMemo } from "react";
import { PaymentOptions } from "../types/PaymentOptions";
import { Countries } from "../../../addresses/Countries";
import { Address } from "../../types/Checkout";
import { getPaymentInformation } from "../../api/getPaymentInformation";

export function usePaymentStepInfo() {
    const { data: paymentStepInfo, isLoading } = useAsyncData(getPaymentInformation, []);

    const defaultBillingAddress = useMemo((): Address => {
        if (paymentStepInfo?.billingAddress) {
            return {
                addressDefault: paymentStepInfo.billingAddress.addressDefault,
                addressLine1: paymentStepInfo.billingAddress?.addressLine1 ?? "",
                addressLine2: paymentStepInfo.billingAddress?.addressLine2 ?? "",
                addressCity: paymentStepInfo.billingAddress?.addressCity ?? "",
                addressPostalCode: paymentStepInfo.billingAddress?.addressPostalCode ?? "",
                addressProvince: paymentStepInfo.billingAddress?.addressProvince ?? "",
                addressCountry:
                    paymentStepInfo.billingAddress?.addressCountry ??
                    Countries.CANADA.toUpperCase(),
            };
        } else {
            return { addressDefault: false };
        }
    }, [JSON.stringify(paymentStepInfo)]);

    const defaultPaymentType = useMemo((): PaymentOptions => {
        if (paymentStepInfo) {
            if (paymentStepInfo.addToAccount && paymentStepInfo.isTermsAvailable) {
                return PaymentOptions.AddPaymentToAccount;
            } else if (paymentStepInfo.payByStoredAccount && paymentStepInfo.storedAccount) {
                return PaymentOptions.UseCreditCardOnFile;
            } else if (
                paymentStepInfo.payByOverrideTermsSalesRep &&
                paymentStepInfo.creditCardOverrideAvailable
            ) {
                return PaymentOptions.OverrideTerms;
            } else {
                return PaymentOptions.PayWithNewCreditCard;
            }
        } else {
            return PaymentOptions.PayWithNewCreditCard;
        }
    }, [JSON.stringify(paymentStepInfo)]);

    return {
        paymentStepInfo,
        isLoading,
        defaultPaymentType,
        defaultBillingAddress,
    };
}
