import React from "react";
import { IconPaths, Icons } from "../Icons";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export type MarathonIconProps = {
  icon: Icons;
} & SvgIconProps;

export const MarathonIcon = ({ icon, ...props }: MarathonIconProps) => {
  return (
    <SvgIcon
      component={IconPaths[icon]}
      inheritViewBox
      {...props}
    />
  );
};
