import { useMemo } from "react";

export function usePrice(currencySymbol: string | undefined, price: number | undefined, unit?: string) {
    return useMemo(() => {
        let formattedPrice: string;

        if (price !== undefined && currencySymbol !== undefined) {
            formattedPrice = currencySymbol + price.toFixed(2) + (unit ? ` / ${unit}` : "");
        } else {
            formattedPrice = "Please Quote";
        }

        return formattedPrice;
    }, [price]);
}
