import React, { ReactElement, useState } from "react";
import {
  ConfirmationDialog as ConfirmationDialogComponent,
  ConfirmationDialogProps,
} from "../components/ConfirmationDialog";

export type ConfirmationDialog = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  component: ReactElement<ConfirmationDialogProps>;
};

export function useConfirmationDialog({
  onConfirm: _onConfirm,
  onDeny: _onDeny,
  children,
  title,
}: Omit<ConfirmationDialogProps, "isOpen">): ConfirmationDialog {
  const [isOpen, setIsOpen] = useState(false);

  function onConfirm() {
    _onConfirm();
    setIsOpen(false);
  }

  function onDeny() {
    _onDeny?.();
    setIsOpen(false);
  }

  const component = (
    <ConfirmationDialogComponent
      isOpen={isOpen}
      onConfirm={onConfirm}
      onDeny={onDeny}
      children={children}
      title={title}
    />
  );

  return {
    isOpen,
    setIsOpen,
    component,
  };
}
