import React, { useMemo, useState } from "react";
import ShippingPopup from "../ShippingPopup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DisplayAddress } from "../components/DisplayAddress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import { ShippingInformation } from "../types/Checkout";

export type DeliverToMeProps = {
  shippingInformation: ShippingInformation;
  selectedAddressId: number;
  setSelectedAddressId: (selectedAddressId: number) => void;
  hasValidationErrors?: boolean;
  errorMessage?: string;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const DeliverToMe: React.FC<DeliverToMeProps> = ({
  shippingInformation,
  selectedAddressId,
  setSelectedAddressId,
  hasValidationErrors,
  errorMessage,
  onBlur,
  onFocus,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const selectedAddress = useMemo(() => {
    const selected = shippingInformation?.shipTos?.find((si) => si.shipToId === selectedAddressId);
    return selected ? (
      <DisplayAddress
        address={{
          addressDefault: false,
          addressName: selected.name,
          addressLine1: selected.address1,
          addressLine2: selected.address2,
          addressCity: selected.city,
          addressProvince: selected.province,
          addressPostalCode: selected.postalCode,
          addressCountry: selected.country,
        }}
      />
    ) : (
      <></>
    );
  }, [shippingInformation, selectedAddressId]);

  return (
    <>
      <Box
        sx={
          hasValidationErrors
            ? { border: 1, borderRadius: 0.75, borderColor: theme.palette.error.main }
            : {}
        }
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div className="inner-frame shipping">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              lg={4}
            >
              <Typography>Default Shipping Address</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
            >
              {selectedAddress}
            </Grid>
          </Grid>
          <button
            type="button"
            onClick={() => setDrawerOpen(true)}
            className="link-w-arrow"
          >
            Choose alternate address
            <ArrowBackIcon color="primary" />
          </button>
        </div>
      </Box>
      {hasValidationErrors && !!errorMessage && (
        <Typography
          pt={2}
          color={theme.palette.error.main}
        >
          {errorMessage}
        </Typography>
      )}
      <ShippingPopup
        selectedShipToId={selectedAddressId}
        setSelectedShipToId={(selectedShipToId) => {
          setSelectedAddressId(selectedShipToId);
          setDrawerOpen(!drawerOpen);
        }}
        shipTos={shippingInformation?.shipTos}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};
