import React from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { AddressEntry } from "./AddressEntry";
import { Address } from "../../checkout/types/Checkout";
import Skeleton from "@mui/material/Skeleton";
import { Icons } from "../../icons";

type MainCompanyAddressProps = {
  mailingAddress: Address | null;
  isMailingAddressLoading: boolean;
  shippingAddress: Address | null;
  isShippingAddressLoading: boolean;
  executeGetShippingAddress: () => Promise<void>;
};

export const MainCompanyAddress = ({
  mailingAddress,
  shippingAddress,
  isShippingAddressLoading,
  isMailingAddressLoading,
  executeGetShippingAddress,
}: MainCompanyAddressProps) => {
  return (
    <>
      <MarathonCard>
        <h3>Main Company Address</h3>
        {isMailingAddressLoading || mailingAddress === null ? (
          <Skeleton height={200} />
        ) : (
          <AddressEntry
            addressTitle={"Mailing/Billing Address"}
            address={mailingAddress}
            isEditable={false}
            displayAddressProps={{ icon: Icons.DefaultLocation }}
          />
        )}
        <hr />
        {isShippingAddressLoading || shippingAddress === null ? (
          <Skeleton height={200} />
        ) : (
          <AddressEntry
            addressTitle={"Shipping Address"}
            address={shippingAddress}
            editorProps={{
              hideDelete: true,
              onSuccessfulDelete: executeGetShippingAddress,
              onSuccessfulUpdate: executeGetShippingAddress,
            }}
          />
        )}
      </MarathonCard>
    </>
  );
};
