import { sendPost } from "@torqit/torq-tools-react";
import { UpdateCustomerTypePayload } from "../types/UpdateCustomerTypePayload";

import { RedirectResponse } from "../../common/types/RedirectResponse";

export async function updateUser(payload: UpdateCustomerTypePayload) {
    return sendPost<UpdateCustomerTypePayload, RedirectResponse>(
        "/user-account/update-customer-type",
        payload
    );
}
