import { useEffect, useState } from "react";

const TIME_TO_CLEAR_IS_SUCCESSFUL = 8000;

export type MakeAsyncCallArguments<
    ApiMethod extends (...args: any[]) => Response,
    AdditionalParams extends object | undefined,
    Response = ReturnType<ApiMethod>
> = {
    apiMethod: ApiMethod;
    additionalParameters?: AdditionalParams;
    onSuccess?: (response: Awaited<Response>, additionalParams?: AdditionalParams) => void;
    onError?: (message: string | undefined, additionalParams?: AdditionalParams) => void;
};

export function makeAsyncCall<
    ApiMethod extends (...args: any[]) => Response,
    AdditionalParams extends object,
    Response = ReturnType<ApiMethod>
>({
    apiMethod,
    onSuccess,
    onError,
    additionalParameters,
}: MakeAsyncCallArguments<ApiMethod, AdditionalParams, Response>) {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [data, setData] = useState<Awaited<Response> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState<boolean | null>(null);

    useEffect(() => {
        const timer = setTimeout(() => setIsSuccessful(null), TIME_TO_CLEAR_IS_SUCCESSFUL);
        return () => clearTimeout(timer);
    }, [isSuccessful]);

    async function callApi(...args: Parameters<ApiMethod>) {
        setIsLoading(true);
        try {
            const response = await apiMethod(...args);
            setData(response);
            setIsSuccessful(true);
            onSuccess?.(response, additionalParameters);
        } catch (e: any) {
            setIsSuccessful(false);
            setErrorMessage(e.message);
            onError?.(e.message, additionalParameters);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        data,
        setData,
        isLoading,
        setIsLoading,
        isSuccessful,
        errorMessage,
        setIsSuccessful,
        callApi,
    };
}
