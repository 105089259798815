import React from "react";

export const RESPONSE_FROM_WORLDPAY = "response-from-worldpay";

export type SendCreditCardSubmissionMessageProps = {
  response?: string;
};

export const SendCreditCardSubmissionMessage = ({
  response,
}: SendCreditCardSubmissionMessageProps) => {
  window.parent.postMessage({
    type: RESPONSE_FROM_WORLDPAY,
    response: response ? JSON.parse(response) : undefined,
  });

  return <></>;
};
