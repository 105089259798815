import React from "react";
import { Countries } from "../Countries";
import { EnumSelector, EnumSelectorProps } from "../../forms/components/EnumSelector";

type CountrySelectorProps = Omit<EnumSelectorProps, "options">;

export const CountrySelector = (props: CountrySelectorProps) => {
  return (
    <EnumSelector
      {...props}
      options={Countries}
    />
  );
};
