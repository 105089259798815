import React from "react";
import { useAsyncData, useAsyncDataCallback } from "@torqit/torq-tools-react";
import { getCurrentUser } from "../api/getCurrentUser";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme/theme";
import { CurrentUserProvider } from "../hooks/CurrentUserProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "react-error-boundary";
import { MarathonError } from "./MarathonError";
import type { User } from "../../user-accounts/types/User";

type RootProps = {
  children: React.ReactNode;
  reactUser?: User;
};

export const Root = ({ children, reactUser }: RootProps) => {
  const {
    data: user,
    isLoading: userLoading,
    executeRequest: reloadUser,
  } = useAsyncDataCallback(getCurrentUser, [], reactUser);

  return (
    <ErrorBoundary FallbackComponent={MarathonError}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CurrentUserProvider user={user} reloadUser={reloadUser} userLoading={userLoading}>
            <ToastContainer position="bottom-left" />
            {children}
          </CurrentUserProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
