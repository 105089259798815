import React, { useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Address } from "../../checkout/types/Checkout";
import { DisplayAddress, DisplayAddressProps } from "../../checkout/components/DisplayAddress";
import { MarathonAccordion } from "../../common/components/MarathonAccordion";
import { FakeTextButton } from "../../common/components/FakeTextButton";
import { AddressEditor, AddressEditorProps } from "./AddressEditor";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { CustomerType } from "../../user-accounts/types/CustomerType";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type AddressEntryProps = {
  address: Address;
  addressTitle: string;
  isEditable?: boolean;
  editorProps?: Omit<AddressEditorProps, "address">;
  displayAddressProps?: Omit<DisplayAddressProps, "address">;
};

export const AddressEntry = ({
  addressTitle,
  address,
  isEditable = true,
  editorProps,
  displayAddressProps,
}: AddressEntryProps) => {
  const { user } = useCurrentUser();

  // FIXME: Update to use permission service when it exists
  const isPrivileged =
    user?.customerType === CustomerType.Buyer || user?.customerType === CustomerType.Admin;

  const [isEditorOpen, setIsEditorOpen] = useState(false);

  return (
    <>
      <MarathonAccordion expanded={isEditorOpen}>
        <AccordionSummary
          sx={{ userSelect: "text" }}
          onClick={() => {
            if (isPrivileged && isEditable && !window.getSelection()?.toString()) {
              setIsEditorOpen(!isEditorOpen);
            }
          }}
        >
          <Grid
            container
            rowSpacing={2}
          >
            <Grid
              item
              xs={10}
              md={4}
            >
              <Stack>
                <Typography variant={"body1"}>{addressTitle}</Typography>
                {address.addressDefault && (
                  <Typography
                    variant={"body2"}
                    fontWeight={"bold"}
                  >
                    Default
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={10}
              md={6}
              justifyContent={"flex-start"}
            >
              <DisplayAddress
                address={address}
                {...displayAddressProps}
              />
            </Grid>
            <Grid
              item
              xs={2}
              justifyContent={"flex-end"}
            >
              {isPrivileged && (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  {isEditable && <FakeTextButton>Edit</FakeTextButton>}
                </Box>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <AddressEditor
            address={address}
            hideDelete={editorProps?.hideDelete}
            hideMakeDefault={editorProps?.hideMakeDefault}
            onSuccessfulDelete={editorProps?.onSuccessfulDelete}
            onSuccessfulUpdate={(updatedAddress) => {
              setIsEditorOpen(false);
              editorProps?.onSuccessfulUpdate?.(updatedAddress);
            }}
          />
        </AccordionDetails>
      </MarathonAccordion>
    </>
  );
};
