import React, { useCallback, useEffect, useRef } from "react";
import { useForm, useFormState } from "react-final-form";
import { debounce } from "../../common/utils/debounce";

export type SubmitAutomaticallyProps = {
  ignoreFirstRender?: boolean;
  debounceTimeout?: number;
};

/**
 * KNOWN ISSUES WITH `useAsyncData`.
 * Seems to be a feedback loop, where `callApi` triggers the `useEffect`
 * in this function and infinitely calls `callApi`
 * */
export const SubmitAutomatically = ({
  ignoreFirstRender = false,
  debounceTimeout = 0,
}: SubmitAutomaticallyProps) => {
  const { submit } = useForm();
  const { values } = useFormState({ subscription: { values: true } });
  const _submit = useCallback(debounce(submit, debounceTimeout), []);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if ((isFirstRender.current && !ignoreFirstRender) || !isFirstRender.current) {
      _submit();
    }
    isFirstRender.current = false;
  }, [JSON.stringify(values)]);

  return <></>;
};
