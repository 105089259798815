import React from "react";
import { Return } from "../types/Return";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InvoiceItemTitle } from "./InvoiceItemTitle";
import {FakeTextButton} from "../../common/components/FakeTextButton";

export type ReturnCardProps = {
  rma: Return;
};

export const ReturnCard = ({ rma }: ReturnCardProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      p={2}
      borderRadius={6}
      display={"flex"}
      position={"relative"}
      bgcolor={theme.palette.background.default}
    >
      <Box
        borderRadius={1}
        p={4}
        sx={{ flex: 1 }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={2}
          >
            <Grid
              container
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                lg={6}
              >
                <InvoiceItemTitle>RMA Date</InvoiceItemTitle>
                <Typography>{rma.date}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
              >
                <InvoiceItemTitle>RMA Number</InvoiceItemTitle>
                <Typography fontWeight={"bold"}>{rma.id}</Typography>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={12}
              >
                <Divider sx={{ maxWidth: "98%" }} />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                lg={6}
              >
                <InvoiceItemTitle>Invoice Number</InvoiceItemTitle>
                <Typography>
                  <FakeTextButton
                    onClick={() => window.open(`/action/account/invoicedetail?invoiceNumber=${rma.invoiceNumber}`)}
                  >
                    {rma.invoiceNumber}
                  </FakeTextButton>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
              >
                <InvoiceItemTitle>RMA Status</InvoiceItemTitle>
                <Typography>{rma.status}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
