import dayjs from "dayjs";

export const filterDate = (date: Date, invalidDates?: string[]) => {
    let isDateValid = true;
    const dayJsDate = dayjs(date);
    if (dayJsDate.get("day") === 0 || dayJsDate.get("day") === 6) {
        isDateValid = false;
    }

    if (invalidDates?.find((invalidDate) => dayJsDate.isSame(invalidDate))) {
        isDateValid = false;
    }

    return isDateValid;
};
