import React, { useMemo, useRef } from "react";
import { Address } from "../../checkout/types/Checkout";
import { Form, FormRenderProps } from "react-final-form";
import Stack from "@mui/material/Stack";
import { MarathonButton } from "../../common/components/MarathonButton";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { updateAddress } from "../api/updateAddress";
import { deleteAddress } from "../api/deleteAddress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { CustomerType } from "../../user-accounts/types/CustomerType";
import { useConfirmationDialog } from "../../common/hooks/useConfirmationDialog";
import { AddressFields } from "../../addresses/components/AddressFields";
import { fieldNames } from "../../forms/utils/fieldNames";
import { AddressFields as AddressFieldsType } from "../../addresses/types/AddressFields";

export type AddressEditorProps = {
  address: Address;
  onSuccessfulUpdate?: (updatedAddress: Address) => void;
  onSuccessfulDelete?: (shipToId: number) => void;
  hideDelete?: boolean;
  hideMakeDefault?: boolean;
};

export const AddressEditor = ({
  address,
  onSuccessfulUpdate,
  onSuccessfulDelete,
  hideDelete,
  hideMakeDefault,
}: AddressEditorProps) => {
  const { user } = useCurrentUser();
  const formRef = useRef<FormRenderProps<Address>["form"] | null>(null);

  const { setIsOpen: setIsDeleteConfirmationOpen, component: ConfirmationDialog } = useConfirmationDialog({
    title: "Confirm address deletion",
    children: <Box padding={3}>Are you sure you want to delete this address?</Box>,
    onConfirm: () => {
      setIsDeleteConfirmationOpen(false);
      sendDelete(address.shipToId);
    },
  });

  const {
    callApi: sendUpdate,
    isLoading: isUpdateLoading,
    errorMessage: updateErrorMessage,
    isSuccessful: isUpdateSuccessful,
    setIsSuccessful: setIsUpdateSuccessful,
  } = makeAsyncCall({
    apiMethod: updateAddress,
    onSuccess: (response, additionalParams) => {
      additionalParams?.formRef?.current?.restart();
      return onSuccessfulUpdate?.(response);
    },
    additionalParameters: { formRef },
  });

  const {
    callApi: sendDelete,
    isLoading: isDeleteLoading,
    errorMessage: deleteErrorMessage,
    isSuccessful: isDeleteSuccessful,
    setIsSuccessful: setIsDeleteSuccessful,
  } = makeAsyncCall({
    apiMethod: deleteAddress,
    onSuccess: (response) => onSuccessfulDelete?.(response),
  });

  const alert = useMemo(() => {
    if (isUpdateSuccessful === false) {
      return (
        <Alert
          sx={{ marginTop: 2 }}
          onClose={() => setIsUpdateSuccessful(null)}
          severity={"error"}
        >{`Failed to update address: ${updateErrorMessage}`}</Alert>
      );
    } else if (isDeleteSuccessful === false) {
      return (
        <Alert
          sx={{ marginTop: 2 }}
          onClose={() => setIsDeleteSuccessful(null)}
          severity={"error"}
        >{`Failed to delete address: ${deleteErrorMessage}`}</Alert>
      );
    } else {
      return <></>;
    }
  }, [isUpdateSuccessful, isDeleteSuccessful, updateErrorMessage, deleteErrorMessage]);

  return (
    <>
      {ConfirmationDialog}
      <Form<Address>
        onSubmit={({ shipToId, ...address }, form) => {
          formRef.current = form;
          return sendUpdate({ shipToId, address });
        }}
        initialValues={address}
        render={({ handleSubmit }) => (
          <>
            <AddressFields
              showMakeDefault={!hideMakeDefault}
              stackProps={{ gap: 4, pb: 4 }}
              fieldNames={fieldNames<AddressFieldsType, Address>({
                addressName: "addressName",
                addressLine1: "addressLine1",
                addressLine2: "addressLine2",
                addressCity: "addressCity",
                addressCountry: "addressCountry",
                addressPostalCode: "addressPostalCode",
                addressProvince: "addressProvince",
                addressDefault: "addressDefault",
              })}
            />
            <Stack gap={2} direction={"row"}>
              <MarathonButton color={"secondary"} onClick={handleSubmit} isLoading={isUpdateLoading}>
                Submit
              </MarathonButton>
              {!address.addressDefault && !hideDelete && user?.customerType === CustomerType.Admin && (
                <MarathonButton
                  onClick={() => setIsDeleteConfirmationOpen(true)}
                  isLoading={isDeleteLoading}
                  loaderProps={{ sx: { color: "white" } }}
                >
                  Delete
                </MarathonButton>
              )}
            </Stack>
            {alert}
          </>
        )}
      />
    </>
  );
};
