import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { Field, FieldProps } from "react-final-form";
import FormControlLabel from "@mui/material/FormControlLabel";

type MarathonCheckboxProps = {
  name: string;
  label?: string;
  fieldProps?: Omit<FieldProps<any, any>, "render">;
  checkBoxProps?: CheckboxProps;
};

export const MarathonCheckbox = ({
  name,
  label,
  fieldProps,
  checkBoxProps,
}: MarathonCheckboxProps) => {
  return (
    <Field
      type={"checkbox"}
      name={name}
      {...fieldProps}
      render={({ input }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                value={input.value}
                checked={input.checked}
                name={input.name}
                onChange={input.onChange}
                {...checkBoxProps}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};
