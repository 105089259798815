import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { MarathonButton } from "../../../common/components/MarathonButton";
import { ProviderResponse, TwoFactorAuthType } from "../../types/TwoFactor";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { setPreferredProvider } from "../../api/userLogin";

export const VerifyAnotherWay = ({
  providerData,
  reloadProviderData,
  styles,
}: {
  providerData: ProviderResponse;
  reloadProviderData: () => Promise<void>;
  styles?: React.CSSProperties;
}) => {
  const { callApi: setPreferredProviderRequest, isLoading: setPreferredProviderRequestIsLoading } =
    makeAsyncCall({
      apiMethod: setPreferredProvider,
      onSuccess: reloadProviderData,
    });

  const includeButtonIfInListOfProviders = (
    provider: TwoFactorAuthType,
    button: React.ReactNode
  ) => {
    return providerData?.availableProviders.includes(provider) ? button : <></>;
  };

  return (
    <div
      className="authenticate-method"
      style={styles}
    >
      <div className="content">
        <strong>Verify another way</strong>
      </div>
      <Stack gap={1}>
        {setPreferredProviderRequestIsLoading ? (
          <CircularProgress />
        ) : (
          <>
            {includeButtonIfInListOfProviders(
              TwoFactorAuthType.Email,
              <MarathonButton
                key={"set-preferred-two-factor-marathon_two_factor_email_provider-button"}
                color={"secondary"}
                onClick={() => setPreferredProviderRequest(TwoFactorAuthType.Email)}
                disabled={providerData.currentProvider === TwoFactorAuthType.Email}
              >
                Email {providerData.currentProvider === TwoFactorAuthType.Email && "(Current)"}
              </MarathonButton>
            )}
            {includeButtonIfInListOfProviders(
              TwoFactorAuthType.Phone,
              <MarathonButton
                key={"set-preferred-two-factor-marathon_two_factor_text_provider-button"}
                color={"secondary"}
                onClick={() => setPreferredProviderRequest(TwoFactorAuthType.Phone)}
                disabled={providerData.currentProvider === TwoFactorAuthType.Phone}
              >
                Text {providerData.currentProvider === TwoFactorAuthType.Phone && "(Current)"}
              </MarathonButton>
            )}
            {includeButtonIfInListOfProviders(
              TwoFactorAuthType.Authenticator,
              <MarathonButton
                key={"set-preferred-two-factor-authenticator-button"}
                color={"secondary"}
                onClick={() => setPreferredProviderRequest(TwoFactorAuthType.Authenticator)}
                disabled={providerData.currentProvider === TwoFactorAuthType.Authenticator}
              >
                Authenticator{" "}
                {providerData.currentProvider === TwoFactorAuthType.Authenticator && "(Current)"}
              </MarathonButton>
            )}
          </>
        )}
      </Stack>
    </div>
  );
};
