import React from "react";
import { ShippingOptions } from "../types/ShippingOptions";
import { Field, useFormState } from "react-final-form";
import { fieldName } from "../../forms/utils/fieldName";
import { isRequired } from "../../forms/validators/isRequired";
import { DeliverToMe } from "./DeliverToMe";
import { PickupInShowroomFields } from "./PickupInShowroomFields";
import { fieldNames } from "../../forms/utils/fieldNames";
import { PickupFields } from "../types/PickupFields";
import { AddressFields } from "../../addresses/components/AddressFields";
import { AddressFields as AddressFieldsType } from "../../addresses/types/AddressFields";
import { ShippingInformation, ShippingInformationSave } from "../types/Checkout";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export type ShippingOptionComponentProps = {
  shippingInformation: ShippingInformation | null;
};

export const ShippingOptionComponent = ({ shippingInformation }: ShippingOptionComponentProps) => {
  const { values } = useFormState<ShippingInformationSave>();
  if (shippingInformation != null) {
    switch (values?.shippingMethod) {
      case ShippingOptions.DeliverToMe:
        return (
          <Field
            name={fieldName<ShippingInformationSave>("shipTo")}
            validate={isRequired}
            render={({ input, meta }) => (
              <>
                <DeliverToMe
                  selectedAddressId={input.value}
                  setSelectedAddressId={input.onChange}
                  shippingInformation={shippingInformation}
                  hasValidationErrors={(meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)}
                  errorMessage={
                    (meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid) ? meta.error : undefined
                  }
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                />
              </>
            )}
          />
        );
      case ShippingOptions.PickupInShowroom:
        return (
          <PickupInShowroomFields
            shippingInformation={shippingInformation}
            fieldNames={fieldNames<PickupFields, ShippingInformationSave>({
              pickupTime: "pickupTime",
              pickupDate: "pickupDate",
            })}
          />
        );
      case ShippingOptions.New:
        return (
          <Stack spacing={2}>
            <AddressFields
              showMakeDefault
              stackProps={{ gap: 4 }}
              fieldNames={fieldNames<AddressFieldsType, ShippingInformationSave>({
                addressDefault: "newAddress.addressDefault",
                addressName: "newAddress.addressName",
                addressLine1: "newAddress.addressLine1",
                addressLine2: "newAddress.addressLine2",
                addressCity: "newAddress.addressCity",
                addressProvince: "newAddress.addressProvince",
                addressPostalCode: "newAddress.addressPostalCode",
                addressCountry: "newAddress.addressCountry",
              })}
            />
            <Alert severity={"info"}>Address will be saved to your account.</Alert>
          </Stack>
        );
    }
  }

  return <></>;
};
