import React from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { AddressEntry } from "./AddressEntry";
import { AddNewAddress } from "./AddNewAddress";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { CustomerType } from "../../user-accounts/types/CustomerType";
import { useMarathonPagination } from "../../common/hooks/useMarathonPagination";
import Box from "@mui/material/Box";
import { StackedLoaders } from "../../common/components/StackedLoaders";
import { Address } from "../../checkout/types/Checkout";

type AlternateAddressesProps = {
  addresses: Address[] | null;
  isLoading: boolean;
  executeRequest: () => Promise<void>;
};

export const AlternateAddresses = ({
  addresses,
  isLoading,
  executeRequest,
}: AlternateAddressesProps) => {
  const { user } = useCurrentUser();
  const { numLoadersPerPage, paginator, itemsBeingViewed } = useMarathonPagination({
    items: addresses ?? [],
    numItemsPerPage: 3,
    numInitialLoaders: 1,
  });

  // FIXME: Change to permission service eventually
  const isPrivileged =
    user?.customerType === CustomerType.Buyer || user?.customerType === CustomerType.Admin;

  return (
    <>
      <MarathonCard>
        <h3>Alternate Addresses</h3>
        {isLoading || addresses === null ? (
          <StackedLoaders
            numLoaders={numLoadersPerPage}
            skeletonProps={{ height: 200 }}
          />
        ) : addresses.length > 0 ? (
          itemsBeingViewed.map((address, index) => {
            return (
              <React.Fragment key={`address-${addresses.indexOf(address) + 1}`}>
                <AddressEntry
                  address={address}
                  addressTitle={`Address #${addresses.indexOf(address) + 1}`}
                  editorProps={{
                    onSuccessfulUpdate: executeRequest,
                    onSuccessfulDelete: executeRequest,
                  }}
                />
                {itemsBeingViewed.length > index + 1 && <hr />}
              </React.Fragment>
            );
          })
        ) : (
          "No alternate addresses on account."
        )}
        <Box
          paddingTop={4}
          display={"flex"}
          justifyContent={"center"}
        >
          {paginator}
        </Box>
        <hr />
        {isPrivileged && <AddNewAddress onAddAddress={executeRequest} />}
      </MarathonCard>
    </>
  );
};
