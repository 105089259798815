import React from "react";
import { LoginForm } from "./Login/LoginForm";
import { NewToMarathon } from "./Login/NewToMarathon";
import { TwoFactorAuth } from "./TwoFactorAuth/TwoFactorAuth";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { CircularProgress } from "@mui/material";

export const AuthForm = () => {
  const { user: currentUser, isLoading } = useCurrentUser();

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      {currentUser?.twoFactorAuthInProgress ? (
        <TwoFactorAuth />
      ) : (
        <>
          <LoginForm /> <hr />
          <NewToMarathon />
        </>
      )}
    </>
  );
};
