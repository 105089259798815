import React from "react";
import { Box, Skeleton } from "@mui/material";
import { DisplayAddress } from "./DisplayAddress";
import dayjs from "dayjs";
import { Order } from "../types/Order";
import { capitalizeWord } from "../../common/utils/capitalizeWord";
import { ShippingOptions } from "../types/ShippingOptions";

type OrderDetailsProps = {
  order: Order | null;
  isLoading?: boolean;
  title?: React.ReactElement;
};

export const OrderDetails = ({ order, isLoading, title }: OrderDetailsProps) => {
  const today = dayjs().format("D MMMM, YYYY");

  const orderCommentLines = order?.orderComment
    ?.split("<br/>")
    ?.filter((ocl) => ocl !== null || ocl !== "");

  return (
    <>
      <div className="inner-frame confirmation">
        {title ?(
            <Box paddingBottom={4}>{title}</Box>
        ) : null}
        {order?.webReference && (
          <>
            <div className="inner-split">
              Web Reference #
              {isLoading ? <Skeleton height={25} /> : <strong>{order.webReference}</strong>}
            </div>
            <hr />
          </>
        )}
        <div className="inner-split">
          Order Notes
          {isLoading ? (
            <Skeleton height={25} />
          ) : (
            <strong>
              {orderCommentLines?.map((orderCommentLine, i, linesArray) => {
                return (
                  <React.Fragment key={`orderCommentLine-${i})`}>
                    {orderCommentLine}
                    {linesArray.length !== i ? <br /> : ""}
                  </React.Fragment>
                );
              })}
            </strong>
          )}
        </div>
        <hr />
        <div className="inner-split">
          Order Date
          <strong>{today}</strong>
        </div>
        <hr />
        <div className="inner-split">
          {order?.shippingMethod === ShippingOptions.PickupInShowroom
            ? "Pickup Address"
            : "Shipping Address"}
          {isLoading ? (
            <Skeleton height={130} />
          ) : (
            <DisplayAddress
              includeIcon={false}
              address={order?.shippingAddress}
            />
          )}
        </div>
        <hr />
        {order?.paymentMethod?.includes("Credit Card") && (
          <>
            <div className="inner-split">
              Billing Address
              {isLoading ? (
                <Skeleton height={100} />
              ) : (
                <DisplayAddress
                  includeIcon={false}
                  address={order?.billToAddress}
                />
              )}
            </div>
            <hr />
          </>
        )}
        <div className="inner-split">
          Shipping Method
          {isLoading ? (
            <Skeleton height={25} />
          ) : (
            <strong>{capitalizeWord(order?.shippingMethod)}</strong>
          )}
        </div>
        <hr />
        <div className="inner-split">
          Payment Method
          {isLoading ? <Skeleton height={25} /> : <strong>{order?.paymentMethod}</strong>}
        </div>
      </div>
    </>
  );
};
