import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Field } from "react-final-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { isMultipleEmails } from "../../forms/validators/isMultipleEmails";

type MarathonAutocompleteProps = {
  name: string;
  textFieldProps?: TextFieldProps;
};

type AutocompleteOption = { title: string; inputValue: string };

export const SecondaryEmailAutocomplete = ({ name, textFieldProps }: MarathonAutocompleteProps) => {
  const isDisabled = (input: any) => {
    return input.value.split(";").filter((v: string) => v !== "").length >= 3;
  };

  return (
    <Field
      name={name}
      validate={isMultipleEmails}
      render={({ input, meta }) => (
        <>
          <Autocomplete
            sx={{ flex: 1 }}
            value={input.value
              .split(";")
              .filter((v: string) => v !== "")
              .map((v: string) => ({ inputValue: v, title: `Add ${v}` }))}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            multiple
            options={[] as AutocompleteOption[]}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.inputValue)}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              return inputValue !== "" ? [{ inputValue, title: `Add ${inputValue}` }] : [];
            }}
            renderInput={({ inputProps, disabled, ...params }) => {
              const { onChange, value, ...formInputProps } = input;
              return (
                <TextField
                  disabled={isDisabled(input)}
                  error={meta.touched && meta.invalid}
                  helperText={meta.touched ? meta.error : undefined}
                  {...textFieldProps}
                  inputProps={{ ...inputProps, ...formInputProps, disabled: isDisabled(input) }}
                  {...params}
                />
              );
            }}
            onChange={(event, value) => {
              if (value.length > 1) {
                return input.onChange(
                  value.reduce((previousValue, currentValue) => {
                    if (typeof previousValue === "string") {
                      if (typeof currentValue === "string") {
                        return `${previousValue};${currentValue}`;
                      } else {
                        return `${previousValue};${currentValue.inputValue}`;
                      }
                    } else {
                      if (typeof currentValue === "string") {
                        return `${previousValue.inputValue};${currentValue}`;
                      } else {
                        return `${previousValue.inputValue};${currentValue.inputValue}`;
                      }
                    }
                  })
                );
              } else if (value.length === 1) {
                const [option] = value;
                return input.onChange(typeof option === "string" ? option : option.inputValue);
              } else {
                return input.onChange(undefined);
              }
            }}
          />
        </>
      )}
    />
  );
};
