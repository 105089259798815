import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid";

export type DesktopLayoutProps = {
  firstTitle: ReactNode;
  firstSubTitle: ReactNode;
  firstContent: ReactNode;
  secondTitle: ReactNode;
  secondSubTitle: ReactNode;
  secondContent: ReactNode;
};

export const DesktopLayout = ({
  firstTitle,
  firstSubTitle,
  firstContent,
  secondTitle,
  secondSubTitle,
  secondContent,
}: DesktopLayoutProps) => {
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          spacing={2}
        >
          <Grid
            item
            xs={8}
          >
            {firstTitle}
          </Grid>
          <Grid
            item
            xs={4}
          >
            {secondTitle}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
        >
          <Grid
            item
            xs={8}
          >
            {firstSubTitle}
          </Grid>
          <Grid
            item
            xs={4}
          >
            {secondSubTitle}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
        >
          <Grid
            item
            xs={8}
          >
            {firstContent}
          </Grid>
          <Grid
            item
            xs={4}
          >
            {secondContent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
