import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import { MarathonButton } from "./MarathonButton";
import Box from "@mui/material/Box";

export type ConfirmationDialogProps = {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onDeny?: () => void;
};

export const ConfirmationDialog = ({ isOpen, title, children, onConfirm, onDeny }: ConfirmationDialogProps) => {
  return (
    <Dialog open={isOpen} hideBackdrop>
      <Box padding={2}>
        <DialogTitle variant={"h5"}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Stack direction={"row"} gap={3}>
            <MarathonButton color={"secondary"} onClick={onDeny}>
              Cancel
            </MarathonButton>
            <MarathonButton onClick={onConfirm}>Confirm</MarathonButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
