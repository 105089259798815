import React from "react";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "../../common/hooks/useIsMobile";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { CART_ITEM_LABEL_WIDTH } from "./CartItemForm";

export const CartItemSkeleton = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <Stack direction={isMobile ? "column" : "row"} spacing={1}>
        {/* picture */}
        <Box
          pr={isMobile ? undefined : 16}
          display={"flex"}
          justifyContent={isMobile ? "center" : undefined}
          alignItems={"center"}
        >
          <Box minWidth={130} minHeight={130} maxWidth={130} maxHeight={130}>
            <Skeleton variant={"rectangular"} width={"100%"} height={130} />
          </Box>
        </Box>

        {/* info */}
        <Stack spacing={1} width={"100%"} justifyContent={"center"}>
          <Stack direction={"row"} spacing={2}>
            <Skeleton width={CART_ITEM_LABEL_WIDTH} />
            <Skeleton width={"80%"} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Skeleton width={CART_ITEM_LABEL_WIDTH} />
            <Skeleton width={"80%"} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Skeleton width={CART_ITEM_LABEL_WIDTH} />
            <Skeleton width={86} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
