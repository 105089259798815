import React from "react";
import { CircularProgress } from "@mui/material";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { createUser2FACodeLogin, getProviders } from "../../api/userLogin";
import { useAsyncData } from "@torqit/torq-tools-react";
import { SendBackupCodeForm } from "./SendBackupCodeForm";
import { ProviderInformation } from "./ProviderInformation";
import { VerifyAnotherWay } from "./VerifyAnotherWay";
import { ResendCode } from "./ResendCode";
import { TwoFactorAuthForm } from "./TwoFactorAuthForm";
import { toast } from "react-toastify";

export const TwoFactorAuth = () => {
  const {
    data: providerData,
    isLoading: isProvidersLoading,
    executeRequest: reloadProviderData,
  } = useAsyncData(getProviders, []);

  const {
    callApi,
    isLoading,
    isSuccessful: authenticationSuccessful,
  } = makeAsyncCall({
    apiMethod: createUser2FACodeLogin,
    onSuccess: () => {
      document.cookie = "pimcore_admin_sid=true";
      window.location.reload();
    },
    onError: (errorMessage) => {
      toast.error(errorMessage, { position: "top-left" });
      reloadProviderData();
    },
  });

  return (
    <>
      <h2>Verification</h2>
      {isProvidersLoading || !providerData || authenticationSuccessful ? (
        <CircularProgress />
      ) : (
        <>
          <ResendCode providerData={providerData} />
          <ProviderInformation providerData={providerData} reloadProviderData={reloadProviderData} />
          <TwoFactorAuthForm providerData={providerData} onSubmit={callApi} submitting={isLoading} />
          <hr />
          <VerifyAnotherWay
            providerData={providerData}
            reloadProviderData={reloadProviderData}
            styles={{ paddingBottom: "30px" }}
          />
          <hr />
          <SendBackupCodeForm />
        </>
      )}
    </>
  );
};
