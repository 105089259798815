import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Cart } from "../../checkout/types/Cart";
import { emptyCart } from "../api/emptyCart";
import { useConfirmationDialog } from "../../common/hooks/useConfirmationDialog";
import Typography from "@mui/material/Typography";

type CartPageButtonsProps = {
  cart: Cart | null;
  onEmpty?: () => void;
};

export const CartPageButtons = ({ cart, onEmpty }: CartPageButtonsProps) => {
  const { setIsOpen, component } = useConfirmationDialog({
    title: "Confirm",
    children: <Box padding={3}>Are you sure you want to empty your cart?</Box>,
    onConfirm: handleEmpty,
  });

  async function handleEmpty() {
    await emptyCart();
    onEmpty?.();
  }

  return (
    <>
      {component}
      <Box m={0} display={"flex"} justifyContent={"flex-end"}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <IconButton href={`/list-print/y/n/${cart?.id}`} target={"_blank"}>
            <img
              src="/static/cdboxV2/images/icon-printer-money.svg"
              alt="Print cart with price"
              title="Print cart with price"
            />
          </IconButton>
          <IconButton href={`/list-print/n/n/${cart?.id}`} target={"_blank"}>
            <img
              src="/static/cdboxV2/images/icon-printer.svg"
              alt="Print cart without price"
              title="Print cart without price"
            />
          </IconButton>
          <IconButton onClick={() => setIsOpen(true)}>
            <img src="/static/cdboxV2/images/icon-trash.svg" alt="Empty cart" title="Empty Cart" />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
};
