export enum TwoFactorAuthType {
    Phone = "marathon_two_factor_text_provider",
    Authenticator = "google",
    Email = "marathon_two_factor_email_provider"
}

export type ProviderResponse = {
    currentProvider: string;
    availableProviders: string[];
    phone?: string;
    email?: string;
    locked?: boolean;
    lockedForSeconds?: number;
};
