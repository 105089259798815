import React, { useMemo, useState } from "react";
import { User } from "../types/User";
import { capitalizeWord } from "../../common/utils/capitalizeWord";
import { AccountEditor } from "./AccountEditor";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  MarathonAccordion,
  MarathonAccordionProps,
} from "../../common/components/MarathonAccordion";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import { FakeTextButton } from "../../common/components/FakeTextButton";
import { UserAccountNameAndEmail } from "./UserAccountNameAndEmail";

type AccountEntryProps = {
  userAccount: User;
  onUpdateUserAccount: () => void;
  accordionProps?: Omit<MarathonAccordionProps, "expanded" | "children">;
};

export const AccountEntry = ({
  userAccount,
  onUpdateUserAccount,
  accordionProps,
}: AccountEntryProps) => {
  const { user: currentUser } = useCurrentUser();
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const isUserAccountTheCurrentUser = useMemo(
    () => currentUser?.id === userAccount.id,
    [currentUser, userAccount]
  );

  return (
    <>
      <MarathonAccordion
        expanded={isEditorOpen}
        {...accordionProps}
      >
        <AccordionSummary
          sx={{ userSelect: "text" }}
          onClick={() => {
            if (!isUserAccountTheCurrentUser && !window.getSelection()?.toString()) {
              setIsEditorOpen(!isEditorOpen);
            }
          }}
        >
          <Grid container>
            <Grid
              item
              lg={4}
              xs={10}
            >
              <div>
                {capitalizeWord(userAccount.customerType)}{" "}
                {!userAccount.enabled || userAccount.needsApproval ? (
                  <span style={{ color: "red" }}>
                    ({userAccount.needsApproval ? "Account needs approval" : "Invite not accepted"})
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              xs={10}
              justifyContent={"flex-start"}
            >
              <UserAccountNameAndEmail user={userAccount} />
            </Grid>
            <Grid
              item
              xs={2}
              justifyContent={"flex-end"}
            >
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
              >
                {isUserAccountTheCurrentUser ? (
                  <Typography>(Current User)</Typography>
                ) : (
                  <FakeTextButton>Edit</FakeTextButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <AccountEditor
            userAccount={userAccount}
            onUpdateUserAccount={onUpdateUserAccount}
          />
        </AccordionDetails>
      </MarathonAccordion>
    </>
  );
};
