import React from "react";
import { ShippingOptions } from "../types/ShippingOptions";
import { Icons } from "../../icons";
import { HollowButtonRadioField } from "../../forms/components/HollowButtonRadioField";
import { RadioOption } from "../../common/components/MarathonRadioGroup";
import { DisplayShippingOption } from "./DisplayShippingOption";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type SelectShippingOptionFieldProps = {
  name: string;
};

export const SelectShippingOptionField = ({ name }: SelectShippingOptionFieldProps) => {
  const theme = useTheme();

  const options: RadioOption[] = [
    {
      value: ShippingOptions.DeliverToMe,
      label: (
        <DisplayShippingOption
          icon={Icons.CheckoutDelivery}
          label={"Deliver to me"}
        />
      ),
    },
    {
      value: ShippingOptions.New,
      label: (
        <DisplayShippingOption
          icon={Icons.CheckoutDelivery}
          label={"Deliver to new address"}
        />
      ),
    },
    {
      value: ShippingOptions.PickupInShowroom,
      label: (
        <DisplayShippingOption
          icon={Icons.PickUp}
          label={"Pick up in showroom"}
        />
      ),
    },
  ];

  return (
      <HollowButtonRadioField
        name={name}
        radioGroupProps={{
          options,
          direction: useMediaQuery(theme.breakpoints.down("lg")) ? "column" : "row",
        }}
      />
  );
};
