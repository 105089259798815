import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Form } from "react-final-form";
import { fieldName } from "../../forms/utils/fieldName";
import { CreateUserForm } from "../types/CreateUserForm";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RequiredTextField } from "../../forms/components/RequiredTextField";
import { MarathonButton } from "../../common/components/MarathonButton";
import { valuesMatch } from "../../forms/validators/valuesMatch";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { acceptNewUserInvite } from "../api/acceptNewUserInvite";
import { CircularProgress } from "@mui/material";
import { usePrevious } from "../../global/hooks/usePrevious";
import { toast } from "react-toastify";
import { isPasswordValid } from "../../forms/validators/isPasswordValid";

type AcceptInviteProps = {};

type FormErrors = { secondPassword: undefined | string; password: undefined | string };

const REDIRECT_TIMEOUT = 3000;
const REDIRECT_PATH = "/";

export const AcceptInvite = ({}: AcceptInviteProps) => {
  const [email, token] = window.location.pathname.split("/").slice(-2);

  const { callApi, isLoading, isSuccessful, errorMessage } = makeAsyncCall({
    apiMethod: acceptNewUserInvite,
    onError: (errorMessage) => toast.error(errorMessage),
  });

  const previousSuccess = usePrevious(isSuccessful);
  useEffect(() => {
    if (previousSuccess === null && isSuccessful === true) {
      toast.success("User created successfully. Redirecting...", { autoClose: REDIRECT_TIMEOUT });
      setTimeout(() => (window.location.href = REDIRECT_PATH), REDIRECT_TIMEOUT);
    }
  }, [isSuccessful]);

  return (
    <Box padding={10}>
      <Form<CreateUserForm>
        onSubmit={callApi}
        initialValues={{ token, email }}
        validate={(values): FormErrors => {
          return {
            secondPassword: valuesMatch(
              [values.password, values.secondPassword],
              "Passwords must match"
            ),
            password: isPasswordValid(values.password),
          };
        }}
        render={({ handleSubmit }) => (
          <Stack gap={4}>
            <Typography variant={"h3"}>Create your account</Typography>
            <RequiredTextField
              name={fieldName<CreateUserForm>("token")}
              textFieldProps={{ disabled: true }}
              hidden
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("firstName")}
              textFieldProps={{ label: "First Name", className: "marathonTextField" }}
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("lastName")}
              textFieldProps={{ label: "Last Name" }}
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("title")}
              textFieldProps={{ label: "Job Title" }}
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("email")}
              textFieldProps={{ label: "Email", disabled: true }}
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("password")}
              textFieldProps={{ label: "Password", type: "password" }}
            />
            <RequiredTextField
              name={fieldName<CreateUserForm>("secondPassword")}
              textFieldProps={{ label: "Retype Password", type: "password" }}
            />
            <MarathonButton
              sx={{ maxWidth: "10%" }}
              color={"secondary"}
              onClick={handleSubmit}
            >
              {isLoading ? <CircularProgress /> : "Submit"}
            </MarathonButton>
          </Stack>
        )}
      />
    </Box>
  );
};
