import React from "react";
import { User } from "../types/User";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type UserAccountNameAndEmailProps = {
  user: User;
};

export const UserAccountNameAndEmail = ({ user }: UserAccountNameAndEmailProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const hasName = user.firstName && user.lastName;

  return (
    <Stack>
      <Typography
        fontSize={"large"}
        fontWeight={"bolder"}
      >
        {hasName ? `${user.firstName} ${user.lastName}` : user.email}
      </Typography>
      {!isMobile && hasName && (
        <Typography
          fontSize={"large"}
          fontWeight={"bolder"}
          color={"grey"}
          style={{ overflowWrap: "break-word" }}
        >
          {user.email}
        </Typography>
      )}
    </Stack>
  );
};
