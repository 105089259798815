import React from "react";
import {
  MarathonRadioGroup,
  MarathonRadioGroupProps,
} from "../../common/components/MarathonRadioGroup";
import { Field, FieldProps } from "react-final-form";

export type MarathonRadioFieldProps = {
  name: string;
  radioGroupProps: MarathonRadioGroupProps;
  fieldProps?: Omit<FieldProps<any, any>, "render">;
};

export const MarathonRadioField = ({
  name,
  radioGroupProps,
  fieldProps,
}: MarathonRadioFieldProps) => {
  return (
    <Field
      name={name}
      {...fieldProps}
      render={({ input, meta }) => (
        <MarathonRadioGroup
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          {...radioGroupProps}
        />
      )}
    />
  );
};
