import { sendGet } from "@torqit/torq-tools-react";

export async function getTranslation(term: string, replacements?: Record<string, any>) {
    const locale = document.getElementById("current_locale")?.getAttribute("value");
    const data = await sendGet<string>("/translation/term", { locale, term });
    if (replacements) {
        return Object.entries(replacements).reduce(
            (previousValue, [key, value]) => previousValue.replace(key, value),
            data
        );
    } else {
        return data;
    }
}
