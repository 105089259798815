import React from "react";
import { MarathonButton, MarathonButtonProps } from "./MarathonButton";

export type MarathonOptionButtonProps = { isActive?: boolean } & MarathonButtonProps;

export const MarathonOptionButton = ({
  isActive = false,
  sx,
  ...props
}: MarathonOptionButtonProps) => {
  return (
    <MarathonButton
      sx={{ borderRadius: "24px", px: 4, display: "flex", justifyContent: "start", ...sx }}
      color={"secondary"}
      variant={isActive ? "outlined" : "text"}
      {...props}
    />
  );
};
