import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { MarathonCard } from "../common/components/MarathonCard";
import { OrderTotalCosts } from "../common/components/OrderTotalCosts";
import { CheckoutSteps } from "./Checkout";
import { BackButton } from "./components/BackButton";
import { ClearCheckoutButton } from "./components/ClearCheckoutButton";
import { ProductList } from "./components/ProductList";
import { ProductListAccordion } from "./components/ProductListAccordion";
import { Cart } from "./types/Cart";

type OrderSummaryProps = {
  cart: Cart | null;
  isLoading: boolean;
  checkoutStep: CheckoutSteps;
};

export const CheckoutOrderSummary = ({ cart, isLoading, checkoutStep }: OrderSummaryProps) => {
  return (
    <div>
      <h2>Your Order</h2>
      <h3>Order Summary</h3>
      <MarathonCard>
        <div className="summary">
          {isLoading ? (
            <Skeleton height={59} />
          ) : (
            <>
              {cart?.products && cart?.products.length > 0 && (
                <ProductListAccordion title={"Regular Items"}>
                  <ProductList products={cart?.products} currencySymbol={cart.currencySymbol} />
                </ProductListAccordion>
              )}
              {cart?.accessoryProducts && cart?.accessoryProducts.length > 0 && (
                <ProductListAccordion title={"Accessory Items"}>
                  <ProductList products={cart?.accessoryProducts} currencySymbol={cart.currencySymbol} />
                </ProductListAccordion>
              )}
              {cart?.specialOrderProducts && cart?.specialOrderProducts.length > 0 && (
                <ProductListAccordion title={"Special Order Items"}>
                  <ProductList products={cart?.specialOrderProducts} currencySymbol={cart.currencySymbol} />
                </ProductListAccordion>
              )}
              {cart?.sampleProducts && cart?.sampleProducts.length > 0 && (
                <ProductListAccordion title={"Sample Items"}>
                  <ProductList products={cart?.sampleProducts} currencySymbol={cart.currencySymbol} />
                </ProductListAccordion>
              )}
              {cart?.backOrderedProducts && cart?.backOrderedProducts.length > 0 && (
                <ProductListAccordion title={"Backordered Items"}>
                  <ProductList products={cart?.backOrderedProducts} currencySymbol={cart.currencySymbol} />
                </ProductListAccordion>
              )}
            </>
          )}
          <hr />
          <OrderTotalCosts cart={cart} isLoading={isLoading} areVouchersEditable={false} />
          <hr />
          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <BackButton />
            {checkoutStep !== CheckoutSteps.Confirmed ? <ClearCheckoutButton buttonText={"Clear Checkout"} /> : <></>}
          </Stack>
        </div>
      </MarathonCard>
    </div>
  );
};
