import React from "react";
import { Voucher } from "../types/Voucher";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";

export type AppliedVoucherCardProps = {
  voucher: Voucher;
  onDelete: (voucher: Voucher) => void;
  isAwaitingDelete?: boolean;
  readonly?: boolean;
};

export const AppliedVoucherEntry = ({
  voucher,
  onDelete,
  isAwaitingDelete,
  readonly,
}: AppliedVoucherCardProps) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
      sx={{ pt: 1 }}
    >
      <div>
        <Typography variant={"body1"}>{voucher.code}</Typography>
        {voucher?.description && (
          <Typography
            variant={"body2"}
            fontWeight={"lighter"}
            color={"grey"}
          >
            {voucher.description}
          </Typography>
        )}
      </div>
      {!readonly && (
        <IconButton
          disabled={isAwaitingDelete}
          onClick={() => onDelete(voucher)}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};
