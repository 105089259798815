import { sendGet, sendPost } from "@torqit/torq-tools-react";
import {Order} from "../types/Order";
import {OpenOrdersFilterForm} from "../types/OpenOrdersFilterForm";
import {OrderOptionsSelect} from "../types/OrderOptions";
import {Pagination} from "../../common/types/Pagination";

type Payload = { orders: Order[], options: OrderOptionsSelect, pagination: Pagination };

export async function getOpenOrders(payload: OpenOrdersFilterForm) {
    return sendPost<OpenOrdersFilterForm, Payload>("/account/get-open-orders", payload);
}