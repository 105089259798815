import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { usePrice } from "../hooks/usePrice";
import { CART_ITEM_LABEL_WIDTH } from "./CartItemForm";
import type { Product } from "../types/Product";

type CartItemInformationProps = {
  product?: Product;
  currencySymbol?: string;
};

export const CartItemInformation = ({ product, currencySymbol }: CartItemInformationProps) => {
  const pricePerUnit = usePrice(currencySymbol, product?.pricePerUnit, product?.unitOfMeasure?.salesPricingUnit);
  const originalPricePerUnit =
    product?.originalPricePerUnit && product?.originalPricePerUnit !== product?.pricePerUnit
      ? usePrice(currencySymbol, product?.originalPricePerUnit, product?.unitOfMeasure?.salesPricingUnit)
      : undefined;

  return (
    <>
      <Stack spacing={1}>
        <Typography component={"a"} color={"black"} href={product?.productUrl} fontSize={"20px"} fontWeight={"bold"}>
          {product?.name}
        </Typography>
        <Stack direction={"row"} spacing={2}>
          <Box width={CART_ITEM_LABEL_WIDTH}>
            <Typography fontSize={"18px"}>Part #:</Typography>
          </Box>
          <Typography fontSize={"18px"}>{product?.partNumber}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Box width={CART_ITEM_LABEL_WIDTH}>
            <Typography fontSize={"18px"}>Unit price:</Typography>
          </Box>
          {originalPricePerUnit && (
            <Typography
              fontSize={"18px"}
              fontWeight={"bold"}
              sx={{ textDecoration: originalPricePerUnit ? "line-through" : undefined }}
            >
              {originalPricePerUnit}
            </Typography>
          )}
          <Typography fontSize={"18px"} fontWeight={"bold"} color={originalPricePerUnit ? "red" : undefined}>
            {pricePerUnit}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};
