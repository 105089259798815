import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import { OrderTotalCosts } from "../../common/components/OrderTotalCosts";
import { MarathonCard } from "../../common/components/MarathonCard";
import Box from "@mui/material/Box";
import { MarathonButton } from "../../common/components/MarathonButton";
import { CommonOrderSummaryProps } from "../../common/types/CommonOrderSummaryProps";
import type { Cart } from "../../checkout/types/Cart";
import type { Product } from "../../checkout/types/Product";

type CartOrderSummaryProps = CommonOrderSummaryProps & {
  isPriceUpdating?: boolean;
};

function pushCartToGTM(cart: Cart) {
  // @ts-expect-error
  window.dataLayer = window.dataLayer ?? [];

  const items = [
    ...cart.products,
    ...cart.accessoryProducts,
    ...cart.backOrderedProducts,
    ...cart.sampleProducts,
    ...cart.specialOrderProducts,
  ].map((product: Product) => ({
    item_id: product.partNumber,
    item_name: product.name,
    item_category: product.groupDescription,
    affiliation: "Web Store",
    currency: "CAD",
    item_brand: product.brandName,
    price: product.pricePerUnit,
    quantity: product.quantity,
  }));

  // @ts-expect-error
  window?.dataLayer?.push({
    event: "begin_checkout",
    ecommerce: {
      currency: "CAD",
      value: Number(cart?.subTotal ?? 0),
      items,
    },
  });
}

export const CartOrderSummary = ({ cart, isLoading, isPriceUpdating }: CartOrderSummaryProps) => {
  function checkout() {
    if (cart) {
      pushCartToGTM(cart);
    }
    window.location.href = "/checkout/shipping";
  }

  const canCheckout = useMemo(
    () =>
      !isLoading &&
      !isPriceUpdating &&
      [
        cart?.products,
        cart?.accessoryProducts,
        cart?.backOrderedProducts,
        cart?.specialOrderProducts,
        cart?.sampleProducts,
      ].some((list) => list && list.length > 0),
    [
      isLoading,
      isPriceUpdating,
      cart?.products,
      cart?.accessoryProducts,
      cart?.backOrderedProducts,
      cart?.specialOrderProducts,
      cart?.sampleProducts,
    ]
  );

  return (
    <>
      <MarathonCard>
        <Box px={2} height={"100%"} width={"100%"}>
          <Stack spacing={2}>
            <OrderTotalCosts cart={cart} isLoading={isLoading} isPriceUpdating={isPriceUpdating} />
            <MarathonButton onClick={checkout} color={"secondary"} disabled={!canCheckout} fullWidth>
              Checkout
            </MarathonButton>
          </Stack>
        </Box>
      </MarathonCard>
    </>
  );
};
