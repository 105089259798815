import { useCallback, useEffect, useState } from "react";
import { Cart } from "../types/Cart";
import { CheckoutSteps } from "../Checkout";
import { usePrevious } from "../../global/hooks/usePrevious";
import { getCart } from "../api/getCart";

export type UseCart = {
    cart: Cart | null;
    setCart: (cart: Cart) => void;
    isCartLoading: boolean;
    setIsCartLoading: (isLoading: boolean) => void;
    runGetCart: (startLoader?: boolean) => Promise<void>;
};

export function useCart(checkoutStep: CheckoutSteps, seperateWebkitItems: boolean = false): UseCart {
    const [cart, setCart] = useState<Cart | null>(null);
    const [isCartLoading, setIsCartLoading] = useState(false);

    const runGetCart = useCallback(
        async (startLoader: boolean = true) => {
            if (startLoader) {
                setIsCartLoading(true);
            }
            const data = await getCart(seperateWebkitItems);
            setCart(data);
            setIsCartLoading(false);
        },
        [seperateWebkitItems]
    );

    const previousStep = usePrevious(checkoutStep);

    useEffect(() => {
        if (
            previousStep === undefined ||
            (previousStep === CheckoutSteps.Shipping && checkoutStep === CheckoutSteps.Payment)
        ) {
            runGetCart();
        }
    }, [checkoutStep]);

    return {
        cart,
        setCart,
        isCartLoading,
        setIsCartLoading,
        runGetCart,
    };
}
