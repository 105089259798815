import React from "react";
import { Invoice } from "../types/Invoice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useCurrentUser } from "../../common/hooks/CurrentUserProvider";
import Box from "@mui/material/Box";
import { useUrgencyChecks } from "../hooks/useUrgencyChecks";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InvoiceItemTitle } from "./InvoiceItemTitle";
import { UrgencyIcon } from "./UrgencyIcon";

export type InvoiceCardProps = {
  invoice: Invoice;
};

export const InvoiceCard = ({ invoice }: InvoiceCardProps) => {
  const { user } = useCurrentUser();
  const theme = useTheme();
  const { isPaidInFull, isNonUrgent, urgencyColor } = useUrgencyChecks(invoice);

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      p={2}
      borderRadius={6}
      display={"flex"}
      position={"relative"}
      bgcolor={theme.palette.background.default}
    >
      {!isNonUrgent && <UrgencyIcon invoice={invoice} />}
      <Box
        border={!isNonUrgent ? 1 : undefined}
        borderColor={!isNonUrgent ? urgencyColor : undefined}
        borderRadius={1}
        p={4}
        sx={{ flex: 1 }}
      >
        <Grid container>
          <Grid
            container
            item
            xs={12}
            lg={10}
            spacing={2}
          >
            <Grid
              container
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>Invoice Date</InvoiceItemTitle>
                <Typography>{invoice.date}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>Invoice Number</InvoiceItemTitle>
                <Typography fontWeight={"bold"}>{invoice.id}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>Total Amount</InvoiceItemTitle>
                <Typography>{invoice.currency + invoice.value}</Typography>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={12}
              >
                <Divider sx={{ maxWidth: "98%" }} />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>Due Date</InvoiceItemTitle>
                <Typography color={urgencyColor}>
                  {isPaidInFull ? "Paid in full" : invoice.dueDate}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>Shipping To</InvoiceItemTitle>
                <Typography>{invoice.companyName}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                <InvoiceItemTitle>PO #</InvoiceItemTitle>
                <Typography>{invoice.PONumber}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={2}
            alignItems={"center"}
          >
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"center"}
            >
              <Tooltip title={"View Invoice"} placement={"top"}>
                <IconButton
                  sx={{ display: "flex", justifyContent: "center" }}
                  onClick={() => window.open(`/invoice-print/${user?.customerId}/${invoice.id}`)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"center"}
            >
              <a
                style={{ display: "flex", justifyContent: "center" }}
                download
                href={`/invoice-print/${user?.customerId}/${invoice.id}`}
              >
                <Tooltip title={"Download Invoice"} placement={"top"}>
                  <IconButton>
                    <SystemUpdateAltOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"center"}
            >
              <Tooltip title={"Download Tax Invoice"} placement={"top"}>
                <IconButton
                  sx={{ display: "flex", justifyContent: "center" }}
                  onClick={() => window.open(`/account/download_revver_invoice?cusId=${user?.customerId}&invoiceNumber=${invoice.id}`)}
                >
                  <FileDownloadOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"center"}
            >
              <Tooltip title={"Process Return"} placement={"top"}>
                <IconButton
                  sx={{ display: "flex", justifyContent: "center" }}
                  onClick={() => window.open(`/action/account/invoicedetail?invoiceNumber=${invoice.id}`)}
                >
                  <ReceiptLongOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
