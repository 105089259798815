import React from "react";
import { Field, FieldProps } from "react-final-form";
import {
  HollowButtonRadioGroup,
  HollowButtonRadioGroupProps,
} from "../../common/components/HollowButtonRadioGroup";

export type HollowButtonRadioFieldProps = {
  name: string;
  radioGroupProps: HollowButtonRadioGroupProps;
  fieldProps?: Omit<FieldProps<any, any>, "render">;
};

export const HollowButtonRadioField = ({
  name,
  radioGroupProps,
  fieldProps,
}: HollowButtonRadioFieldProps) => {
  return (
    <Field
      name={name}
      {...fieldProps}
      render={({ input, meta }) => (
        <HollowButtonRadioGroup
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          {...radioGroupProps}
        />
      )}
    />
  );
};
