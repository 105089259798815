import React from "react";
import { useAsyncData } from "@torqit/torq-tools-react";
import { getMailingAddress } from "../api/getMailingAddress";
import { getRegistrationAddress } from "../api/getRegistrationAddress";
import { getAlternateAddresses } from "../api/getAlternateAddresses";
import { MainCompanyAddress } from "./MainCompanyAddress";
import { AlternateAddresses } from "./AlternateAddresses";

type UserAddressesProps = {};

export const UserAddresses = ({}: UserAddressesProps) => {
  const { data: mailingAddress, isLoading: isMailingAddressLoading } = useAsyncData(
    getMailingAddress,
    []
  );

  const {
    data: shippingAddress,
    isLoading: isShippingAddressLoading,
    executeRequest: executeGetShippingAddress,
  } = useAsyncData(getRegistrationAddress, []);

  const {
    data: addresses,
    isLoading: isAlternateAddressesLoading,
    executeRequest: executeGetAlternateAddresses,
  } = useAsyncData(getAlternateAddresses, []);

  const executeRequests = async () => {
    executeGetShippingAddress();
    executeGetAlternateAddresses();
  };

  return (
    <>
      <MainCompanyAddress
        mailingAddress={mailingAddress}
        executeGetShippingAddress={executeRequests}
        isMailingAddressLoading={isMailingAddressLoading}
        isShippingAddressLoading={isShippingAddressLoading}
        shippingAddress={shippingAddress}
      />
      <AlternateAddresses
        addresses={addresses}
        isLoading={isAlternateAddressesLoading}
        executeRequest={executeRequests}
      />
    </>
  );
};
