import React from "react";
import { Form } from "react-final-form";
import { MarathonTextField } from "../../forms/components/MarathonTextField";
import { fieldName } from "../../forms/utils/fieldName";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { addVoucherCode, AddVoucherCodePayload } from "../api/addVoucherCode";
import { MarathonButton } from "../../common/components/MarathonButton";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";

export type NewVoucherFormProps = {
  onVoucherAdd: () => void;
  areVouchersLoading?: boolean;
};

export const NewVoucherForm = ({ onVoucherAdd, areVouchersLoading }: NewVoucherFormProps) => {
  const { callApi, isLoading } = makeAsyncCall({
    apiMethod: addVoucherCode,
    onError: (message) => toast.error(message),
    onSuccess: onVoucherAdd,
  });

  return (
    <Form<AddVoucherCodePayload>
      onSubmit={callApi}
      render={({ handleSubmit, values }) => (
        <Stack
          direction={{ xs: "column", xl: "row" }}
          spacing={2}
        >
          <MarathonTextField
            name={fieldName<AddVoucherCodePayload>("code")}
            textFieldProps={{
              disabled: areVouchersLoading,
              label: "Voucher Code",
              sx: { width: { xs: "100%", xl: "75%" }, backgroundColor: "white" },
            }}
          />
          <MarathonButton
            disabled={values.code === "" || values.code === undefined || areVouchersLoading}
            sx={{ width: { xs: "100%", xl: "25%" } }}
            color={"secondary"}
            onClick={handleSubmit}
            isLoading={isLoading}
            loaderProps={{ sx: { color: "white" } }}
          >
            Apply
          </MarathonButton>
        </Stack>
      )}
    />
  );
};
