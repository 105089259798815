import React, { Fragment, useState } from "react";
import { MarathonOptionButton, MarathonOptionButtonProps } from "./MarathonOptionButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MarathonRadioGroupProps } from "./MarathonRadioGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";

export type HollowButtonRadioGroupProps = Omit<MarathonRadioGroupProps, "onChange"> & {
  onChange?: (value: string) => void;
  optionButtonProps?: MarathonOptionButtonProps;
};

export const HollowButtonRadioGroup = ({
  options,
  control = <div />,
  direction,
  formControlLabelProps,
  onChange,
  optionButtonProps,
  ...props
}: HollowButtonRadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(props.value ?? null);

  return (
    <RadioGroup {...props}>
      <Grid
        container
        justifyContent={"justify-content"}
        gap={2}
      >
        {options.map(({ label, value }) => (
          <Fragment key={value}>
            <Grid
              item
              justifyContent={"start"}
              xs={direction === "row" ? true : 12}
            >
              <MarathonOptionButton
                fullWidth={true}
                isActive={selectedValue === value}
                onClick={() => {
                  setSelectedValue(value);
                  onChange?.(value);
                }}
                {...optionButtonProps}
              >
                <FormControlLabel
                  sx={{ marginRight: 0, marginLeft: 0 }} // https://github.com/mui/material-ui/issues/34039
                  control={control}
                  value={value}
                  label={label}
                  {...formControlLabelProps}
                />
              </MarathonOptionButton>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </RadioGroup>
  );
};
