import React, { useCallback } from "react";
import { Field, FieldMetaState, FieldProps } from "react-final-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { isRequired } from "../validators/isRequired";
import { composeValidators } from "../validators/composeValidators";
import { Validator } from "../validators/types/Validator";

type RequiredTextFieldProps = {
  name: string;
  validators?: Validator[];
  fieldProps?: Omit<FieldProps<any, any>, "name" | "validate">;
  textFieldProps?: Omit<TextFieldProps, "inputProps">;
  inputProps?: TextFieldProps["inputProps"];
  hidden?: boolean;
  hideHelperText?: boolean;
};

export function RequiredTextField({
  name,
  hidden,
  textFieldProps,
  fieldProps,
  inputProps,
  validators = [],
  hideHelperText,
}: RequiredTextFieldProps) {
  return (
    <Field
      {...fieldProps}
      name={name}
      validate={(value, allValues, meta) => composeValidators(isRequired, ...validators)(value, allValues, meta)}
      render={({ input, meta }) =>
        hidden !== true ? (
          <TextField
            required
            inputProps={{ ...input, ...inputProps }}
            error={(meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)}
            helperText={
              !hideHelperText && ((meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid))
                ? meta.error
                : undefined
            }
            {...textFieldProps}
          />
        ) : (
          <></>
        )
      }
    />
  );
}
