// https://codesandbox.io/s/github/final-form/react-final-form/tree/master/examples/field-level-validation?file=/index.js:557-691

import { Validator, ValidatorOutput } from "./types/Validator";

export function composeValidators(...validators: Validator[]): Validator {
    return (...args) =>
        validators.reduce(
            (error: ValidatorOutput, validator) => error || validator(...args),
            undefined
        );
}
