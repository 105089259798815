import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { groupBy } from "../utils/groupBy";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export type MarathonPagination<Item extends any> = {
  itemsBeingViewed: Item[];
  paginator: ReactElement<PaginationProps>;
  numLoadersPerPage: number;
};

export type UseMarathonPaginationArgs<Item extends any> = {
  items: Item[];
  numItemsPerPage?: number;
  numInitialLoaders?: number;
  paginatorProps?: PaginationProps;
};

export function useMarathonPagination<Item extends any>({
  items,
  numItemsPerPage = 5,
  numInitialLoaders = 5,
  paginatorProps,
}: UseMarathonPaginationArgs<Item>): MarathonPagination<Item> {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [currentPage, setCurrentPage] = useState(1);

  const itemsGroupedByPage = useMemo(
    () => groupBy(items, (item, index) => Math.floor(index / numItemsPerPage) + 1),
    [items, currentPage]
  );

  const numPages = useMemo(() => Object.keys(itemsGroupedByPage).length ?? 1, [itemsGroupedByPage]);

  const numLoadersPerPage = useMemo(
    () => itemsGroupedByPage[1]?.length ?? numInitialLoaders,
    [itemsGroupedByPage]
  );

  // stop currentPage from getting out of range
  useEffect(() => {
    if (numPages)
      if (currentPage > numPages) {
        setCurrentPage(numPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }
  }, [currentPage, numPages]);

  const itemsBeingViewed = useMemo(
    () => itemsGroupedByPage[currentPage],
    [itemsGroupedByPage, currentPage]
  );

  const paginator = (
    <Pagination
      page={currentPage}
      count={numPages}
      onChange={(event, page) => setCurrentPage(page)}
      siblingCount={isMobile ? 0 : undefined}
      boundaryCount={isMobile ? 1 : undefined}
      {...paginatorProps}
    />
  );

  return {
    itemsBeingViewed,
    paginator,
    numLoadersPerPage,
  };
}
