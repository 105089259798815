import React, { ReactNode, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { Icons, MarathonIcon } from "../../icons";

export type Props = {
  buttonText?: ReactNode;
  styles?: React.CSSProperties;
};

export const ClearCheckoutButton = ({ buttonText, styles }: Props) => {
  const [loading, setIsLoading] = useState(false);

  return (
    <a
      onClick={() => {
        if (!loading) {
          setIsLoading(true);
          window.location.href = "/checkout/clear";
        }
      }}
      style={styles}
    >
      <button
        type="button"
        className="link-w-icon"
      >
        <Stack
          justifyContent={"center"}
          alignContent={"center"}
          direction={"row"}
          gap={3}
        >
          <MarathonIcon icon={Icons.Trash} />
          {buttonText}
        </Stack>
      </button>

      {loading ? <CircularProgress style={{ padding: "7.5px" }} /> : <></>}
    </a>
  );
};
