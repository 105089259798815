import { Key } from "../types/Key";

export type GroupBy<T extends any> = { [key: Key]: T[] };

export function groupBy<T extends any>(
    array: T[],
    grouping: (item: T, index: number, array: T[]) => Key
): GroupBy<T> {
    return [...array].reduce<GroupBy<T>>((previousValue, currentValue, currentIndex, array) => {
        const group = grouping(currentValue, currentIndex, array);
        previousValue[group] = previousValue[group] || [];
        previousValue[group].push(currentValue);
        return previousValue;
    }, {});
}
