import React from "react";

export const CreditCardDisclaimer: React.FC<{ locationId: string }> = ({ locationId }) => {
  return locationId ? (
    <>
      <h3>Secure Credit Card</h3>
      <div className="inner-frame">
        <div className="column small-12">
          <p className="text-sm">
            Credit Cards are securely processed by{" "}
            <a
              href="https://www.fisglobal.com/en/merchant-solutions-worldpay"
              target="_blank"
              className="text-maximum-red hover:underline"
            >
              Worldpay
            </a>{" "}
            on behalf of Marathon. We do not store your credit card number on our servers or
            computers. Worldpay maintains the highest level of security in the credit card
            processing industry. We will only charge your card for the order amount including taxes
            and any applicable shipping charges at the time of invoicing/shipping only. Please note
            that only the items being shipped will be invoiced.
          </p>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CreditCardDisclaimer;
