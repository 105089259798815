import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CheckoutSteps } from "../../checkout/Checkout";
import { useCart } from "../../checkout/hooks/useCart";
import { useIsMobile } from "../../common/hooks/useIsMobile";
import { CartOrderSummary } from "./CartOrderSummary";
import { CartPageButtons } from "./CartPageButtons";
import { DesktopLayout } from "./DesktopLayout";
import { MobileLayout } from "./MobileLayout";
import { ShoppingCart } from "./ShoppingCart";

type CartProps = {};

export const Cart = ({}: CartProps) => {
  const isMobile = useIsMobile();
  const { cart, isCartLoading, runGetCart } = useCart(CheckoutSteps.Shipping, false);
  const [isPriceUpdating, setIsPriceUpdating] = useState(false);

  const onProductUpdate = useCallback(async () => {
    setIsPriceUpdating(true);
    await runGetCart(false);
    setIsPriceUpdating(false);
  }, []);

  useEffect(() => {
    const cartQty = document.getElementById("update_cart_qty");
    if (cartQty && cart?.totalNumProducts) {
      cartQty.textContent = cart.totalNumProducts.toFixed();
    } else if (cartQty && cart?.totalNumProducts == 0) {
      cartQty.textContent = "0";
    }
  }, [cart?.totalNumProducts]);

  const props = useMemo(
    () => ({
      firstTitle: <Typography variant={"h2"}>Shopping Cart</Typography>,
      firstSubTitle:
        !isCartLoading && cart?.products && cart.products.length > 0 ? (
          <Typography variant={"h3"} textAlign={"center"}>
            Regular Items
          </Typography>
        ) : (
          <></>
        ),
      firstContent: (
        <ShoppingCart
          cart={cart}
          isLoading={isCartLoading}
          isPriceUpdating={isPriceUpdating}
          onProductUpdate={onProductUpdate}
        />
      ),
      secondTitle: <Typography variant={"h2"}>Order Summary</Typography>,
      secondSubTitle: <CartPageButtons cart={cart} onEmpty={runGetCart} />,
      secondContent: <CartOrderSummary cart={cart} isLoading={isCartLoading} isPriceUpdating={isPriceUpdating} />,
    }),
    [cart, isCartLoading, isPriceUpdating, onProductUpdate]
  );

  return (
    <>
      <Box px={{ xs: 2, lg: 8 }}>{isMobile ? <MobileLayout {...props} /> : <DesktopLayout {...props} />}</Box>
    </>
  );
};
