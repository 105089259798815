import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MarathonAccordion } from "../../common/components/MarathonAccordion";
import React, { ReactNode } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

type ProductListAccordionProps = {
  title: string;
  children: ReactNode;
};

export const ProductListAccordion = ({ title, children }: ProductListAccordionProps) => {
  return (
    <>
      <MarathonAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h4>{title}</h4>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MarathonAccordion>
    </>
  );
};
