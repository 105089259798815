import React, { ReactElement, StrictMode } from "react";
import ReactDOM from "react-dom";
import { Root } from "../components/Root";
import "react-toastify/dist/ReactToastify.css";

export const createRoot = (
  node: ReactElement | ((dataset: DOMStringMap) => ReactElement),
  container: HTMLElement | null
) => {
  if (container) {
    ReactDOM.render(
      <StrictMode>
        <Root reactUser={container?.dataset?.reactuser ? JSON.parse(container.dataset.reactuser) : undefined}>
          {typeof node === "function" ? node(container.dataset) : node}
        </Root>
      </StrictMode>,
      container
    );
  }
};
