import React from "react";
import { MarathonButton } from "../../../common/components/MarathonButton";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { resendCode } from "../../api/userLogin";
import { ProviderResponse, TwoFactorAuthType } from "../../types/TwoFactor";
import { toast } from "react-toastify";

export const ResendCode = ({ providerData }: { providerData: ProviderResponse }) => {
  const { callApi: resendCodeApiCall, isLoading: resendCodeLoading } = makeAsyncCall({
    apiMethod: resendCode,
    onError: () => toast.error("Error resending code.", { position: "top-left" }),
    onSuccess: (response) => toast.success(response.message || "Code resent", { position: "top-left" }),
  });

  return providerData &&
    providerData.currentProvider !== TwoFactorAuthType.Authenticator &&
    providerData.locked !== true ? (
    <MarathonButton
      sx={{ maxWidth: "5%" }}
      style={{ float: "right" }}
      color={"secondary"}
      variant="text"
      onClick={() => resendCodeApiCall(providerData.currentProvider)}
      isLoading={resendCodeLoading}
    >
      <span>Resend Code</span>
    </MarathonButton>
  ) : (
    <></>
  );
};
