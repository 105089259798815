import React, { Fragment } from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { ProductList } from "../../checkout/components/ProductList";
import { CommonOrderSummaryProps } from "../../common/types/CommonOrderSummaryProps";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CartItemSkeleton } from "../../checkout/components/CartItemSkeleton";
import Divider from "@mui/material/Divider";

type ShoppingCartProps = CommonOrderSummaryProps & {
  isPriceUpdating?: boolean;
  onProductUpdate: () => void;
};

export const ShoppingCart = ({ cart, isLoading, isPriceUpdating, onProductUpdate }: ShoppingCartProps) => {
  const lists = [
    { title: "Accessory Items", list: cart?.accessoryProducts },
    { title: "Special Order Items", list: cart?.specialOrderProducts },
    { title: "Back Ordered Items", list: cart?.backOrderedProducts },
    { title: "Sample Items", list: cart?.sampleProducts },
  ];

  return isLoading ? (
    <MarathonCard>
      <Stack spacing={2} divider={<Divider />}>
        <CartItemSkeleton />
        <CartItemSkeleton />
        <CartItemSkeleton />
      </Stack>
    </MarathonCard>
  ) : (
    <Stack spacing={2}>
      {cart?.products && cart.products.length > 0 ? (
        <MarathonCard>
          <ProductList
            products={cart.products}
            isPriceUpdating={isPriceUpdating}
            currencySymbol={cart?.currencySymbol ?? "$"}
            inEditMode={true}
            onProductUpdate={onProductUpdate}
          />
        </MarathonCard>
      ) : lists.some(({ list }) => list && list.length > 0) ? (
        <></>
      ) : (
        <>
          <Typography variant={"h3"} textAlign={"center"}>
            Your shopping cart is empty.
          </Typography>
        </>
      )}
      {lists.map(
        ({ list, title }) =>
          list &&
          list.length > 0 && (
            <Fragment key={title}>
              <Stack spacing={2}>
                <Typography variant={"h3"} textAlign={"center"}>
                  {title}
                </Typography>
                <MarathonCard>
                  <ProductList
                    products={list}
                    isPriceUpdating={isPriceUpdating}
                    currencySymbol={cart?.currencySymbol ?? "$"}
                    inEditMode={true}
                    onProductUpdate={onProductUpdate}
                  />
                </MarathonCard>
              </Stack>
            </Fragment>
          )
      )}
    </Stack>
  );
};
