import React from "react";
import { OrderDetails } from "./OrderDetails";
import { Box, Grid, IconButton } from "@mui/material";
import { ConfirmOrderResponse } from "../types/ConfirmOrderResponse";
import { Icons, MarathonIcon } from "../../icons";
import { ColoredMarathonIcon } from "../../icons/components/ColoredMarathonIcon";
import { ColoredIcons } from "../../icons/Icons";

type OrderConfirmedProps = {
  order: ConfirmOrderResponse["order"] | null;
  isLoading: boolean;
  tokenWithPrice: ConfirmOrderResponse["tokenWithPrice"] | null;
  tokenNoPrice: ConfirmOrderResponse["tokenNoPrice"] | null;
};

export const OrderConfirmed = ({
  order,
  isLoading,
  tokenWithPrice,
  tokenNoPrice,
}: OrderConfirmedProps) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          md={9}
        >
          <h2><span className="num">4.</span> Order Confirmed</h2>
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
        >
          <Box
            display={"flex"}
            justifyContent={"end"}
          >
            <IconButton
              href={`/action/webtoprint/orderPrint?t=${tokenWithPrice}`}
              target="_blank"
            >
              <ColoredMarathonIcon icon={ColoredIcons.InvoiceWithPrice} />
            </IconButton>
            <IconButton
              href={`/action/webtoprint/orderPrint?t=${tokenNoPrice}`}
              target="_blank"
            >
              <ColoredMarathonIcon icon={ColoredIcons.InvoiceNoPrice} />
            </IconButton>
          </Box>
        </Grid>
        <h3>Your order has been confirmed.</h3>
      </Grid>
      <OrderDetails
        title={
          <p>
            Thank you for your order! It has now been submitted to our order desk for processing.
            You will receive a copy of this order confirmation as email. If you have any questions
            about your order, please <a href={"/locations"}>contact your local branch</a>.
          </p>
        }
        order={order}
        isLoading={isLoading}
      />
    </>
  );
};
