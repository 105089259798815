import React, { useMemo } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import { RequiredTextField } from "../../forms/components/RequiredTextField";
import { MarathonTextField } from "../../forms/components/MarathonTextField";
import { asPostalCode } from "../../forms/formatters/asPostalCode";
import { isPostalCode } from "../../forms/validators/isPostalCode";
import { CountrySelector } from "./CountrySelector";
import { isRequired } from "../../forms/validators/isRequired";
import { StateSelector } from "./StateSelector";
import { ProvinceSelector } from "./ProvinceSelector";
import { AddressFields as AddressFieldsType } from "../types/AddressFields";
import { MarathonCheckbox } from "../../forms/components/MarathonCheckbox";
import { useField } from "react-final-form";

type AddressFieldsProps = {
  fieldNames: Record<keyof AddressFieldsType, string>;
  stackProps?: StackProps;
  showMakeDefault?: boolean;
  hideAddressName?: boolean;
};

export const AddressFields = ({
  fieldNames,
  stackProps,
  hideAddressName = false,
  showMakeDefault = false,
}: AddressFieldsProps) => {
  const {
    input: { value: country },
  } = useField<string>(fieldNames.addressCountry);
  const isUSA = useMemo(() => /[Uu][Ss][Aa]?/.test(country), [country]);

  return (
    <>
      <Stack {...stackProps}>
        {!hideAddressName && (
          <RequiredTextField
            name={fieldNames.addressName}
            textFieldProps={{ label: "Address Name" }}
          />
        )}
        <RequiredTextField
          name={fieldNames.addressLine1}
          textFieldProps={{ label: "Address Line 1" }}
        />
        <MarathonTextField
          name={fieldNames.addressLine2}
          textFieldProps={{ label: "Address Line 2" }}
        />
        <RequiredTextField
          name={fieldNames.addressCity}
          textFieldProps={{ label: "Address City" }}
        />
        <RequiredTextField
          fieldProps={{ format: asPostalCode }}
          name={fieldNames.addressPostalCode}
          validators={[isPostalCode]}
          textFieldProps={{ label: "Address Postal Code" }}
          inputProps={{ maxLength: 6 }}
        />
        <CountrySelector
          name={fieldNames.addressCountry}
          fieldProps={{ validate: isRequired }}
          textFieldProps={{ label: "Country", required: true }}
        />
        {isUSA ? (
          <StateSelector
            name={fieldNames.addressProvince}
            fieldProps={{ validate: isRequired }}
            textFieldProps={{
              label: "State",
              required: true,
              SelectProps: {
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 500,
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <ProvinceSelector
            name={fieldNames.addressProvince}
            fieldProps={{ validate: isRequired }}
            textFieldProps={{
              label: "Province",
              required: true,
              SelectProps: {
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 500,
                    },
                  },
                },
              },
            }}
          />
        )}
        {showMakeDefault && (
          <MarathonCheckbox
            name={fieldNames.addressDefault}
            label={"Make Address Default"}
          />
        )}
      </Stack>
    </>
  );
};
