import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { MarathonCheckbox } from "../../forms/components/MarathonCheckbox";
import { MarathonTextField } from "../../forms/components/MarathonTextField";
import { asPhoneNumber } from "../../forms/formatters/asPhoneNumber";
import { PickupInShowroomFieldsProps } from "./PickupInShowroomFields";
import { useForm, useFormState } from "react-final-form";
import { validateEmailField, validateTextField } from "../utils/validateNotifactionFields";
import { ShippingInformationSave } from "../types/Checkout";
import { AdditionalInformationFieldsProps } from "./AdditionalInformationFields";

export type PickupNotificationFieldsProps = Pick<AdditionalInformationFieldsProps, "fieldNames">;

export const PickupNotificationFields = ({ fieldNames }: PickupNotificationFieldsProps) => {
  const { values } = useFormState<ShippingInformationSave>();
  const { mutators } = useForm<ShippingInformationSave>();

  useEffect(() => {
    if (!values.shouldReceiveEmailNotifications && !values.shouldReceiveTextNotifications) {
      mutators.setEmail(fieldNames.shouldReceiveEmailNotifications, true);
    }
  }, [values.shouldReceiveTextNotifications, values.shouldReceiveEmailNotifications]);

  return (
    <>
      <Grid
        container
        rowSpacing={8}
        columnSpacing={4}
        pt={4}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Grid
            item
            xs={12}
            pb={2}
          >
            <MarathonCheckbox
              name={fieldNames.shouldReceiveEmailNotifications}
              label={"Receive email notifications"}
              checkBoxProps={{ disabled: !values.shouldReceiveTextNotifications }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <MarathonTextField
              name={fieldNames.emailNotificationValue}
              fieldProps={{
                validate: validateEmailField,
              }}
              textFieldProps={{
                label: "Email address",
                sx: { minWidth: "100%" },
                required: values.shouldReceiveEmailNotifications,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Grid
            item
            xs={12}
            pb={2}
          >
            <MarathonCheckbox
              name={fieldNames.shouldReceiveTextNotifications}
              label={"Receive text notifications"}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <MarathonTextField
              name={fieldNames.textNotificationValue}
              fieldProps={{
                validate: validateTextField,
                format: asPhoneNumber,
              }}
              textFieldProps={{
                label: "Phone number",
                sx: { minWidth: "100%" },
                required: values.shouldReceiveTextNotifications,
              }}
              inputProps={{ maxLength: 17 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
