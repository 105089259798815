export enum Provinces {
    AB = "Alberta",
    BC = "British Columbia",
    MB = "Manitoba",
    NB = "New Brunswick",
    NL = "Newfoundland",
    NS = "Nova Scotia",
    ON = "Ontario",
    PE = "Prince Edward Island",
    QC = "Quebec",
    SK = "Saskatchewan",
    NT = "Northwest Territories",
    NU = "Nunavut",
    YT = "Yukon",
}