import React from "react";
import { Stack } from "@mui/material";
import { MarathonButton } from "../../../common/components/MarathonButton";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { sendBackupCode } from "../../api/userLogin";
import { toast } from "react-toastify";

export const SendBackupCodeForm = () => {
  const { callApi: sendBackupCodeRequest, isLoading: sendBackupCodeIsLoading } = makeAsyncCall({
    apiMethod: sendBackupCode,
    onSuccess: () =>
      toast.success("Backup code sent successfully, please enter code, and submit.", {
        position: "top-left",
      }),
    onError: (errorMessage) => {
      toast.success(errorMessage, { position: "top-left" });
    },
  });

  return (
    <div className="authenticate-method">
      <div className="content">
        <strong>Send Backup Code</strong>
      </div>
      <Stack gap={1}>
        <MarathonButton
          color={"secondary"}
          onClick={() => {
            if (
              confirm(
                "Your backup code will be sent to the email that you logged in with. Once your backup code has been entered above and verified, your current 2FA methods will be disabled. To continue using 2FA, you will need to re-enable your 2FA preferred methods in your profile."
              )
            ) {
              sendBackupCodeRequest();
            }
          }}
          isLoading={sendBackupCodeIsLoading}
        >
          Send Backup Code
        </MarathonButton>
      </Stack>
    </div>
  );
};
