import React from "react";
import { InvoiceReturnOptions } from "../types/InvoiceReturnOptions";
import { Icons } from "../../icons";
import { HollowButtonRadioField } from "../../forms/components/HollowButtonRadioField";
import { RadioOption } from "../../common/components/MarathonRadioGroup";
import { DisplayInvoiceReturnOption } from "./DisplayInvoiceReturnOption";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type SelectInvoiceReturnOptionFieldProps = {
  name: string;
};

export const SelectInvoiceReturnOptionField = ({ name }: SelectInvoiceReturnOptionFieldProps) => {
  const theme = useTheme();

  const options: RadioOption[] = [
    {
      value: InvoiceReturnOptions.Invoice,
      label: (
        <DisplayInvoiceReturnOption
          icon={Icons.Invoice}
          label={"Invoices & Return"}
        />
      ),
    },
    {
      value: InvoiceReturnOptions.Return,
      label: (
        <DisplayInvoiceReturnOption
          icon={Icons.Return}
          label={"Return Status"}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <HollowButtonRadioField
          name={name}
          radioGroupProps={{
            options,
            direction: useMediaQuery(theme.breakpoints.down("lg")) ? "column" : "row",
          }}
        />
      </div>
    </>
  );
};
