import React from "react";
import { User } from "../types/User";
import { CustomerType } from "../types/CustomerType";
import Stack from "@mui/material/Stack";
import { MarathonButton } from "../../common/components/MarathonButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useAccountEditor } from "../hooks/useAccountEditor";
import { CustomerTypeSelector } from "./CustomerTypeSelector";
import Alert from "@mui/material/Alert";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { inviteNewUser } from "../api/inviteNewUser";
import { useConfirmationDialog } from "../../common/hooks/useConfirmationDialog";
import { approveUser } from "../api/approveUser";

type AccountEditorProps = {
  userAccount: User;
  onUpdateUserAccount: () => void;
};

export const AccountEditor = ({ userAccount, onUpdateUserAccount }: AccountEditorProps) => {
  const {
    runDelete,
    update,
    updateIsLoading,
    deleteIsLoading,
    isUpdateSuccessful,
    setIsUpdateSuccessful,
    updateErrorMessage,
    setCustomerType,
    customerType,
    isDeleteSuccessful,
    deleteErrorMessage,
    setIsDeleteSuccessful,
  } = useAccountEditor(userAccount, onUpdateUserAccount);

  const {
    callApi,
    isLoading: invitingNewUserLoading,
    isSuccessful: invitingNewUserSuccessful,
    setIsSuccessful: setInvitingNewUserSuccessful,
    errorMessage: invitingNewUserErrorMessage,
  } = makeAsyncCall({
    apiMethod: inviteNewUser,
    onSuccess: onUpdateUserAccount,
  });

  const {
    callApi: approveUserApi,
    isLoading: approvingUser,
    isSuccessful: approvingUserSuccessful,
    setIsSuccessful: setApprovingUserSuccessful,
    errorMessage: approvingUserErrorMessage,
  } = makeAsyncCall({
    apiMethod: approveUser,
    onSuccess: onUpdateUserAccount,
  });

  const { setIsOpen: setIsConfirmationDialogOpen, component: ConfirmationDialog } =
    useConfirmationDialog({
      title: "Confirm user deletion",
      children: "You can re-activate a deleted user by sending them a new invite",
      onConfirm: runDelete,
    });

  return (
    <>
      {ConfirmationDialog}
      <Stack
        alignItems={"flex-start"}
        gap={2}
      >
        <CustomerTypeSelector
          value={customerType}
          onChange={(event) => setCustomerType(event.target.value as CustomerType)}
        />
        <Stack
          direction={{ sx: "column", lg: "row" }}
          gap={2}
        >
          <MarathonButton
            color={"secondary"}
            onClick={update}
          >
            {updateIsLoading ? <CircularProgress /> : "Save"}
          </MarathonButton>
          {!userAccount.enabled && !userAccount.needsApproval && (
            <MarathonButton
              color={"info"}
              onClick={async () => {
                await callApi({ email: userAccount.email, customerType });
              }}
            >
              {invitingNewUserLoading ? <CircularProgress /> : "Resend Invite Link"}
            </MarathonButton>
          )}
          {!userAccount.enabled && userAccount.needsApproval && (
            <MarathonButton
              color={"info"}
              onClick={async () => {
                await approveUserApi(userAccount.id);
              }}
            >
              {approvingUser ? <CircularProgress /> : "Approve User"}
            </MarathonButton>
          )}
          {userAccount.customerType !== CustomerType.Admin && (
            <MarathonButton
              color={"primary"}
              onClick={() => setIsConfirmationDialogOpen(true)}
              loaderProps={{ sx: { color: "white" } }}
              isLoading={deleteIsLoading}
            >
              Delete User
            </MarathonButton>
          )}
        </Stack>
        {isUpdateSuccessful === false && (
          <Alert
            severity={"error"}
            onClose={() => setIsUpdateSuccessful(null)}
          >
            {updateErrorMessage}
          </Alert>
        )}
        {isDeleteSuccessful === false && (
          <Alert
            severity={"error"}
            onClose={() => setIsDeleteSuccessful(null)}
          >
            {deleteErrorMessage}
          </Alert>
        )}
        {invitingNewUserSuccessful !== null && (
          <Alert
            severity={invitingNewUserSuccessful ? "success" : "error"}
            onClose={() => setInvitingNewUserSuccessful(null)}
          >
            {invitingNewUserSuccessful ? "Invite resent" : invitingNewUserErrorMessage}
          </Alert>
        )}
        {approvingUserSuccessful !== null && (
          <Alert
            severity={approvingUserSuccessful ? "success" : "error"}
            onClose={() => setApprovingUserSuccessful(null)}
          >
            {approvingUserSuccessful ? "User approved" : approvingUserErrorMessage}
          </Alert>
        )}
      </Stack>
    </>
  );
};
