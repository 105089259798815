import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

export type OrderItemTitleProps = TypographyProps;

export const OrderItemTitle = (props: OrderItemTitleProps) => {
    return (
        <Typography
            {...props}
            fontSize={16}
            fontWeight={"bold"}
            color={"grey"}
        />
    );
};