export function asPhoneNumber(value?: string): string {
    if (value) {
        value = value.replace(/[^0-9+()\s-]/g, "");
        if (/^\+1\d{1,9}$/.test(value)) {
            const matches = value.match(/\+1(\d*)/);
            return matches?.[1] ?? value;
        } else if (/^\d{10}$/.test(value)) {
            const matches = value.match(/(\+?1?)(\d{3})(\d{3})(\d{4})/);
            return `+1 (${matches?.[2]}) ${matches?.[3]}-${matches?.[4]}`;
        } else {
            return value;
        }
    } else {
        return "";
    }
}
