import React from "react";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import deepmerge from "deepmerge";

export type MarathonAccordionProps = Omit<AccordionProps, "elevation">;

export const MarathonAccordion = ({ children, sx, ...props }: MarathonAccordionProps) => {
  sx = deepmerge<AccordionProps["sx"]>(sx, {
    userSelect: "text",
    boxShadow: 0,
    border: 0,
    "&:before": {
      display: "none",
    },
  });

  return (
    <Accordion
      elevation={0}
      sx={sx}
      {...props}
    >
      {children}
    </Accordion>
  );
};
