import React, { Fragment } from "react";
import { AppliedVoucherEntry } from "./AppliedVoucherEntry";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { deleteVoucher } from "../api/deleteVoucher";
import { toast } from "react-toastify";
import { Voucher } from "../types/Voucher";
import Stack from "@mui/material/Stack";

export type AppliedVouchersProps = {
  vouchers: Voucher[];
  onVoucherDelete?: () => void;
  readonly?: boolean;
};

export const AppliedVouchers = ({ vouchers, onVoucherDelete, readonly }: AppliedVouchersProps) => {
  const { callApi, isLoading } = makeAsyncCall({
    apiMethod: deleteVoucher,
    onError: (message) => toast.error(message),
    onSuccess: onVoucherDelete,
  });

  return (
    <Stack>
      {vouchers.map((voucher) => (
        <Fragment key={voucher.id}>
          <AppliedVoucherEntry
            voucher={voucher}
            onDelete={callApi}
            isAwaitingDelete={isLoading}
            readonly={readonly}
          />
        </Fragment>
      ))}
    </Stack>
  );
};
