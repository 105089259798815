import React, { useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import { InvoiceFilterForm } from "../types/InvoiceFilterForm";
import { PartialDeepOmit } from "../../common/types/DeepOmit";

export type ResetPageNumberProps = {};

export const ResetPageNumber = ({}: ResetPageNumberProps) => {
  const { mutators } = useForm();
  const { values } = useFormState<InvoiceFilterForm>({ subscription: { values: true } });

  const allButPageNumber = useMemo<PartialDeepOmit<InvoiceFilterForm, "pageNumber">>(
    () => ({
      pagination: {
        itemsPerPage: values.pagination.itemsPerPage,
      },
      shipToId: values.shipToId,
      startDate: values.startDate,
      endDate: values.endDate,
    }),
    [JSON.stringify(values)]
  );

  useEffect(() => mutators.resetPageNumber(), [JSON.stringify(allButPageNumber)]);

  return <></>;
};
