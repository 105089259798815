import React from "react";
import { Form } from "react-final-form";
import { Stack } from "@mui/material";
import { RequiredTextField } from "../../../forms/components/RequiredTextField";
import { fieldName } from "../../../forms/utils/fieldName";
import { MarathonButton } from "../../../common/components/MarathonButton";
import { Create2FACodeLogin } from "../../types/Create2FACodeLogin";
import { MarathonCheckbox } from "../../../forms/components/MarathonCheckbox";
import { ProviderResponse } from "../../types/TwoFactor";

type FormErrors = { authCode: undefined | string };

export const TwoFactorAuthForm = ({
  providerData,
  onSubmit,
  submitting,
}: {
  providerData: ProviderResponse;
  onSubmit: (payload: Create2FACodeLogin) => Promise<void>;
  submitting: boolean;
}) => {
  return (
    <Form<Create2FACodeLogin>
      onSubmit={onSubmit}
      validate={(values): FormErrors => {
        return {
          authCode: values?.authCode?.length !== 6 ? "Auth code must be 6 characters long." : undefined,
        };
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Stack gap={1}>
            <RequiredTextField
              name={fieldName<Create2FACodeLogin>("authCode")}
              textFieldProps={{ label: "Auth Code" }}
              inputProps={{ maxLength: 6 }}
            />
            <MarathonCheckbox
              name={fieldName<Create2FACodeLogin>("_trusted_marathon_hardware")}
              label="I'm on a trusted computer"
            />
            <MarathonButton
              sx={{ maxWidth: "10%" }}
              color={"secondary"}
              type="submit"
              disabled={providerData?.locked}
              isLoading={submitting}
            >
              <span>Submit</span>
            </MarathonButton>
          </Stack>
        </form>
      )}
    />
  );
};
