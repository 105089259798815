import React from "react";
import { Invoice } from "../types/Invoice";
import { useUrgencyChecks } from "../hooks/useUrgencyChecks";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { IconProps } from "@mui/material/Icon";

export type UrgencyIconProps = {
  invoice: Invoice;
};

export const UrgencyIcon = ({ invoice }: UrgencyIconProps) => {
  const { isDueSoon, isPastDue } = useUrgencyChecks(invoice);

  const iconSxProp: IconProps["sx"] = {
    position: "absolute",
    zIndex: 2,
    top: "0px",
    right: "0px",
  };

  return isPastDue ? (
    <Tooltip title={"Payment overdue. Please make a payment ASAP."}>
      <ErrorIcon
        color={"error"}
        sx={iconSxProp}
      />
    </Tooltip>
  ) : isDueSoon ? (
    <Tooltip title={"Payment due soon. Please make a payment ASAP."}>
      <WarningIcon
        color={"warning"}
        sx={iconSxProp}
      />
    </Tooltip>
  ) : (
    <></>
  );
};
