import React from "react";
import { MarathonCard } from "./MarathonCard";
import { Stack } from "@mui/material";

export const MarathonError = () => {
  return (
    <MarathonCard>
      <Stack
        padding={10}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img
          src="/static/cdboxV2/images/marathon-logo-horizontal.svg"
          width="300"
        />
        <h4>Sorry we encountered an error. Please refresh your page and try again.</h4>
      </Stack>
    </MarathonCard>
  );
};
