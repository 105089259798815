import "../src/common/css/marathonTextField.css";
import React from "react";
import Checkout from "./checkout/Checkout";
import { UserAccounts } from "./user-accounts/components/UserAccounts";
import { UserAddresses } from "./user-addresses/components/UserAddresses";
import { AcceptInvite } from "./accept-invite/components/AcceptInvite";
import { createRoot } from "./common/utils/createRoot";
import { TestAgGrid } from "./workshop/TestAgGrid";
import { AuthForm } from "./login/components/AuthForm";
import { Invoices } from "./invoices/components/Invoices";
import { Vouchers } from "./vouchers/components/Vouchers";
import { MyOrders } from "./my-orders/components/MyOrders";
import { SendCreditCardSubmissionMessage } from "./checkout/payment/components/SendCreditCardSubmissionMessage";
import { Cart } from "./cart/components/Cart";

createRoot(<Checkout />, document.getElementById("react-checkout-dashboard"));
createRoot(<Cart />, document.getElementById("react-cart"));
createRoot(<UserAccounts />, document.getElementById("react-user-accounts"));
createRoot(<AcceptInvite />, document.getElementById("react-accept-invite"));
createRoot(<UserAddresses />, document.getElementById("react-user-addresses"));
createRoot(<AuthForm />, document.getElementById("react-login-form-side-panel"));
createRoot(<TestAgGrid />, document.getElementById("react-test-ag-grid"));
createRoot(<Invoices />, document.getElementById("react-invoices"));
createRoot(<MyOrders />, document.getElementById("react-orders"));
createRoot(
  (dataset) => <SendCreditCardSubmissionMessage response={dataset.response} />,
  document.getElementById("react-redirect")
);
