import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type BackButtonProps = {
  onClick?: () => void;
};

export const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <a href="/action/account/cart">
      <button
        className="link-w-arrow"
        onClick={onClick}
      >
        Back to cart
        <ArrowBackIcon color="primary" />
      </button>
    </a>
  );
};
