import React from "react";
import { Drawer } from "@mui/material";
import { ShipTo } from "./types/Checkout";

export const ShippingPopup: React.FC<{
  shipTos?: ShipTo[];
  selectedShipToId: number;
  setSelectedShipToId: (selectedShipToId: number) => void;
  drawerOpen: boolean;
  setDrawerOpen: (drawerOpen: boolean) => void;
}> = ({ shipTos, selectedShipToId, setSelectedShipToId, drawerOpen, setDrawerOpen }) => {
  return (
    <Drawer
      id="addresses"
      anchor={"right"}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      style={{ zIndex: 9999999 }}
      PaperProps={{
        style: { padding: "24px 50px", borderRadius: "25px 0 0 25px" },
      }}
    >
      <button
        style={{ marginLeft: "auto", display: "block", marginBottom: "12px" }}
        aria-label="Close menu"
        type="button"
        onClick={() => setDrawerOpen(false)}
      >
        <img
          src="/static/cdboxV2/images/icon-close.svg"
          alt=""
        />
      </button>
      <h2 style={{ fontWeight: 600 }}>Alternate addresses</h2>
      <br />
      {shipTos ? (
        shipTos.map((shipTo) => {
          return (
            <button
              key={`shipToBtn_${shipTo.shipToId}`}
              className={"address " + (selectedShipToId === shipTo.shipToId ? "active" : "")}
              type="button"
              onClick={() => setSelectedShipToId(shipTo.shipToId)}
            >
              <div className="name">
                {shipTo.name} - {shipTo.address1}
              </div>
              <strong>
                {shipTo.address2} {shipTo.city}, {shipTo.province} {shipTo.country}{" "}
                {shipTo.postalCode}
              </strong>
            </button>
          );
        })
      ) : (
        <>None</>
      )}
    </Drawer>
  );
};

export default ShippingPopup;
