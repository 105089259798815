import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";

export type MarathonButtonProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
  loaderProps?: CircularProgressProps;
} & ButtonProps;

export const MarathonButton = ({
  children,
  sx,
  isLoading,
  loaderProps,
  ...props
}: MarathonButtonProps) => {
  return (
    <>
      <Button
        sx={{ py: 1.5, px: 8, fontWeight: 600, fontSize: 16, ...sx }}
        size={"large"}
        variant={"contained"}
        {...props}
      >
        {isLoading ? <CircularProgress {...loaderProps} /> : children}
      </Button>
    </>
  );
};
