import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Field, FieldProps } from "react-final-form";

export type MarathonTextFieldProps = {
  name: string;
  fieldProps?: Omit<FieldProps<any, any>, "render">;
  textFieldProps?: Omit<TextFieldProps, "inputProps">;
  inputProps?: TextFieldProps["inputProps"];
};

export const MarathonTextField = ({
  name,
  fieldProps,
  textFieldProps,
  inputProps,
}: MarathonTextFieldProps) => {
  return (
    <Field
      name={name}
      {...fieldProps}
      render={({ input, meta }) => (
        <TextField
          inputProps={{ ...inputProps, ...input }}
          error={(meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)}
          helperText={
            (meta.dirty && meta.invalid) || (meta.submitFailed && meta.invalid)
              ? meta.error
              : undefined
          }
          {...textFieldProps}
        />
      )}
    />
  );
};
