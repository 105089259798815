import { Validator } from "../../forms/validators/types/Validator";
import { composeValidators } from "../../forms/validators/composeValidators";
import { isRequired } from "../../forms/validators/isRequired";
import { isEmail } from "../../forms/validators/isEmail";
import { isPhoneNumber } from "../../forms/validators/isPhoneNumber";
import { ShippingInformationSave } from "../types/Checkout";

export const validateEmailField: Validator<
    ShippingInformationSave["emailNotificationValue"],
    ShippingInformationSave
> = (value, allValues) => {
    if (allValues?.shouldReceiveEmailNotifications) {
        return composeValidators(isRequired, isEmail)(value);
    } else {
        return isEmail(value);
    }
};

export const validateTextField: Validator<
    ShippingInformationSave["textNotificationValue"],
    ShippingInformationSave
> = (value, allValues) => {
    if (allValues?.shouldReceiveTextNotifications) {
        return composeValidators(isRequired, isPhoneNumber)(value);
    } else {
        return isPhoneNumber(value);
    }
};
