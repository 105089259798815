import React, { useState } from "react";
import { MarathonAccordion } from "../../common/components/MarathonAccordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AddressEditor, AddressEditorProps } from "./AddressEditor";
import { FakeTextButton } from "../../common/components/FakeTextButton";

type AddAddressProps = {
  onAddAddress: Required<AddressEditorProps>["onSuccessfulUpdate"];
};

export const AddNewAddress = ({ onAddAddress }: AddAddressProps) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  return (
    <>
      <MarathonAccordion expanded={isEditorOpen}>
        <AccordionSummary
          onClick={() => setIsEditorOpen(!isEditorOpen)}
          sx={{ "& .MuiAccordionSummary-content": { justifyContent: "flex-end" } }}
        >
          <FakeTextButton>Add new address</FakeTextButton>
        </AccordionSummary>
        <AccordionDetails>
          <AddressEditor
            address={{ addressDefault: false, shipToId: 0 }}
            hideDelete={true}
            onSuccessfulUpdate={(updatedAddress) => {
              setIsEditorOpen(false);
              onAddAddress(updatedAddress);
            }}
          />
        </AccordionDetails>
      </MarathonAccordion>
    </>
  );
};
