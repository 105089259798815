import React from "react";
import { DesktopLayoutProps } from "./DesktopLayout";
import Stack from "@mui/material/Stack";

type MobileLayoutProps = DesktopLayoutProps;

export const MobileLayout = ({
  firstTitle,
  firstSubTitle,
  firstContent,
  secondTitle,
  secondSubTitle,
  secondContent,
}: MobileLayoutProps) => {
  return (
    <>
      <Stack spacing={2}>
        {firstTitle}
        {firstSubTitle}
        {firstContent}
        {secondTitle}
        {secondSubTitle}
        {secondContent}
      </Stack>
    </>
  );
};
