import { Grid, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { AddressFields } from "../../../addresses/components/AddressFields";
import { AddressFields as AddressFieldsType } from "../../../addresses/types/AddressFields";
import { HollowButtonRadioGroup } from "../../../common/components/HollowButtonRadioGroup";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { fieldNames } from "../../../forms/utils/fieldNames";
import { CheckoutSteps } from "../../Checkout";
import { saveBillingAddress } from "../api/saveBillingAddress";
import { PaymentData, savePaymentData } from "../api/savePaymentData";
import { usePaymentStepInfo } from "../hooks/usePaymentStepInfo";
import { PaymentFormData } from "../types/PaymentFormData";
import { PaymentOptions } from "../types/PaymentOptions";
import { CheckoutStepNavigationButtons } from "./CheckoutStepNavigationButtons";
import { DisplayCreditCard } from "./DisplayCreditCard";
import { SelectPaymentOption } from "./SelectPaymentOption";

type PaymentProps = {
  setCheckoutStep(checkoutStep: CheckoutSteps): void;
};

export const Payment: React.FC<PaymentProps> = ({ setCheckoutStep }) => {
  const theme = useTheme();
  const direction = useMediaQuery(theme.breakpoints.down("lg")) ? "column" : "row";

  const {
    paymentStepInfo,
    isLoading: paymentStepInfoIsLoading,
    defaultPaymentType,
    defaultBillingAddress,
  } = usePaymentStepInfo();

  const { callApi: _saveBillingAddress, isLoading: isSavingBillingAddress } = makeAsyncCall({
    apiMethod: saveBillingAddress,
    onSuccess: () => setCheckoutStep(CheckoutSteps.CreditCardEntry),
    onError: (message) => toast.error(message || "Error saving payment data."),
  });

  const { callApi: _savePaymentData } = makeAsyncCall({
    apiMethod: savePaymentData,
    onSuccess: ({ redirectTo }) => {
      if (redirectTo) {
        window.location.href = redirectTo;
      } else {
        setCheckoutStep(CheckoutSteps.Confirmation);
      }
    },
  });

  return (
    <Form<PaymentData>
      initialValues={{ billingAddress: defaultBillingAddress, paymentOptions: defaultPaymentType }}
      onSubmit={(values) =>
        values.paymentOptions === PaymentOptions.PayWithNewCreditCard
          ? _saveBillingAddress(values)
          : _savePaymentData(values)
      }
      render={({ values, handleSubmit }) => (
        <div>
          <h2>Billing</h2>
          <h3>How would you like to pay?</h3>
          {paymentStepInfoIsLoading || paymentStepInfo === null ? (
            <Grid container justifyContent={"justify-content"} gap={2}>
              <Skeleton height={115} width={310} sx={{ transformOrigin: "top" }} />
              <Skeleton height={750} sx={{ transformOrigin: "top", width: "100%" }} />
            </Grid>
          ) : (
            <div className="inner-frame">
              <Box pb={1}>
                <SelectPaymentOption paymentStepInfo={paymentStepInfo} />
                {values.paymentOptions === PaymentOptions.UseCreditCardOnFile ? (
                  <>
                    {/* FIXME: Update this to support multiple credit cards later on */}
                    <HollowButtonRadioGroup
                      direction={direction}
                      value={"default"}
                      sx={{ pt: 4 }}
                      options={[
                        {
                          label: <DisplayCreditCard paymentStepInfo={paymentStepInfo} />,
                          value: "default",
                        },
                      ]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </div>
          )}
          {!paymentStepInfoIsLoading && values.paymentOptions === PaymentOptions.PayWithNewCreditCard ? (
            <>
              <br />
              <Typography variant={"h3"} pt={{ xs: 2, md: 0 }}>
                Billing address
              </Typography>
              <div className="inner-frame">
                <AddressFields
                  hideAddressName
                  stackProps={{ gap: 2, sx: { pl: 2, maxWidth: { xs: "100%", lg: "100%" } } }}
                  fieldNames={fieldNames<AddressFieldsType, PaymentFormData>({
                    addressDefault: "billingAddress.addressDefault",
                    addressName: "billingAddress.addressName",
                    addressLine1: "billingAddress.addressLine1",
                    addressLine2: "billingAddress.addressLine2",
                    addressCity: "billingAddress.addressCity",
                    addressCountry: "billingAddress.addressCountry",
                    addressPostalCode: "billingAddress.addressPostalCode",
                    addressProvince: "billingAddress.addressProvince",
                  })}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <Box pt={4}>
            <CheckoutStepNavigationButtons
              goBack={() => setCheckoutStep(CheckoutSteps.Shipping)}
              goBackText={"Back to Shipping"}
              goForward={handleSubmit}
              isForwardLoading={isSavingBillingAddress}
              goForwardText={"Continue"}
              forwardButtonProps={{ fullWidth: true }}
              backButtonProps={{ fullWidth: true }}
            />
          </Box>
        </div>
      )}
    />
  );
};

export default Payment;
