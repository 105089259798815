import React from "react";
import { fieldName } from "../../forms/utils/fieldName";
import { InvoiceFilterForm } from "../types/InvoiceFilterForm";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import { Field } from "react-final-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type InvoicePaginatorProps = {
  count: PaginationProps["count"];
};

export const InvoicePaginator = ({ count }: InvoicePaginatorProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Field
        name={fieldName<InvoiceFilterForm>("pagination.pageNumber")}
        render={({ input }) => (
          <Pagination
            count={count}
            onChange={(event, page) => input.onChange(page)}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            page={input.value}
            siblingCount={isMobile ? 0 : undefined}
            boundaryCount={isMobile ? 1 : undefined}
          />
        )}
      />
    </>
  );
};
