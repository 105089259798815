import React, { ReactNode } from "react";
import { MarathonTextField, MarathonTextFieldProps } from "./MarathonTextField";
import deepmerge from "deepmerge";

export type MarathonSelectFieldProps = MarathonTextFieldProps & {
  children: ReactNode;
};

export const MarathonSelectField = (_props: MarathonSelectFieldProps) => {
  let { children, ...props } = _props;
  props = deepmerge(props, {
    textFieldProps: {
      select: true,
      children,
    },
  });
  return <MarathonTextField {...props} />;
};
