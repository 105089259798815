import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useAsyncData } from "@torqit/torq-tools-react";
import React from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { makeAsyncCall } from "../../../common/hooks/makeAsyncCall";
import { useIsMobile } from "../../../common/hooks/useIsMobile";
import { CheckoutSteps } from "../../Checkout";
import { getWorldpayUrl } from "../api/getWorldpayUrl";
import { PaymentData, savePaymentData } from "../api/savePaymentData";
import { usePaymentStepInfo } from "../hooks/usePaymentStepInfo";
import { PaymentOptions } from "../types/PaymentOptions";
import { CheckoutStepNavigationButtons } from "./CheckoutStepNavigationButtons";
import CreditCardDisclaimer from "./CreditCardDisclaimer";
import { TransactionDataFields } from "./TransactionDataFields";

export type CreditCardFormProps = {
  setCheckoutStep(checkoutStep: CheckoutSteps): void;
};

export const CreditCardForm = ({ setCheckoutStep }: CreditCardFormProps) => {
  const isMobile = useIsMobile();
  const { paymentStepInfo } = usePaymentStepInfo();
  const { data: worldpayUrl, isLoading } = useAsyncData(getWorldpayUrl, []);

  const { callApi, isLoading: isSavingPaymentData } = makeAsyncCall({
    apiMethod: savePaymentData,
    onSuccess: () => setCheckoutStep(CheckoutSteps.Confirmation),
    onError: (message) => toast.error(message),
  });

  return (
    <>
      <Form<PaymentData>
        mutators={{
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal);
          },
        }}
        onSubmit={(values) =>
          callApi({
            paymentOptions: PaymentOptions.PayWithNewCreditCard,
            transactionData: values.transactionData,
          })
        }
        render={({ handleSubmit, values }) => (
          <>
            <Typography variant={"h2"}>Credit Card Details</Typography>
            <CreditCardDisclaimer locationId={paymentStepInfo?.LocationID || ""} />
            {isLoading || worldpayUrl === null ? (
              <Skeleton />
            ) : values?.transactionData?.TransactionID ? (
              <></>
            ) : (
              <Box pt={4}>
                <TransactionDataFields worldPayUrl={worldpayUrl} onFormDataSet={handleSubmit} />
              </Box>
            )}
            <Box pt={4}>
              <CheckoutStepNavigationButtons
                goBack={() => setCheckoutStep(CheckoutSteps.Payment)}
                goBackText={"Return to billing"}
                goForward={handleSubmit}
                goForwardText={"Continue"}
                backButtonProps={{ fullWidth: true }}
                isForwardLoading={isSavingPaymentData}
                forwardButtonHidden
              />
            </Box>
          </>
        )}
      />
    </>
  );
};
