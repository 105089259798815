import React from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { NewVoucherForm } from "./NewVoucherForm";
import { AppliedVouchers } from "./AppliedVouchers";
import Skeleton from "@mui/material/Skeleton";
import List from "@mui/material/List";
import { toast } from "react-toastify";
import { useAsyncData } from "@torqit/torq-tools-react";
import { getAppliedVouchers } from "../api/getAppliedVouchers";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export type VouchersProps = {
  isEditable?: boolean;
  onVoucherDelete?: () => void;
  onVoucherAdd?: () => void;
};

export const Vouchers = ({ isEditable = true, onVoucherDelete, onVoucherAdd }: VouchersProps) => {
  const { data: vouchers, isLoading: areVouchersLoading } = useAsyncData(getAppliedVouchers, []);

  const reload = () => window.location.reload();

  return (
    <Stack spacing={2}>
      {isEditable ? (
        <NewVoucherForm
          onVoucherAdd={onVoucherAdd ?? reload}
          areVouchersLoading={areVouchersLoading}
        />
      ) : (
        <></>
      )}
      {areVouchersLoading ? (
        <List>
          <Skeleton sx={{ mb: 2 }} />
        </List>
      ) : (
        vouchers &&
        vouchers.length > 0 && (
          <>
            <strong>{"Applied Vouchers"}</strong>
            <Box px={2}>
              <AppliedVouchers
                vouchers={vouchers}
                onVoucherDelete={onVoucherDelete ?? reload}
                readonly={!isEditable}
              />
            </Box>
          </>
        )
      )}
    </Stack>
  );
};
