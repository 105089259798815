import React, { createContext, useContext } from "react";
import { User } from "../../user-accounts/types/User";

const CurrentUserContext = createContext<{
  user: User | undefined | null;
  reloadUser: () => Promise<void>;
  userLoading: boolean;
}>({ user: null, reloadUser: () => Promise.resolve(), userLoading: false });

export type CurrentUserProviderProps = {
  children: React.ReactNode;
  user?: User | null;
  reloadUser: () => Promise<void>;
  userLoading: boolean;
};

export function CurrentUserProvider({
  children,
  user,
  reloadUser,
  userLoading,
}: CurrentUserProviderProps) {
  return (
    <CurrentUserContext.Provider
      value={{
        user: user,
        reloadUser: reloadUser,
        userLoading,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUser() {
  const result = useContext(CurrentUserContext);

  if (result === null) {
    throw new Error(
      "useCurrentUser() cannot be used without being wrapped by a CurrentUserProvider"
    );
  }

  return { user: result.user, isLoading: result.userLoading };
}

export function reloadCurrentUser() {
  const result = useContext(CurrentUserContext);

  if (result === null) {
    throw new Error(
      "reloadCurrentUser() cannot be used without being wrapped by a CurrentUserProvider"
    );
  }

  return result.reloadUser;
}
