import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

export type InvoiceFilterBarLoaderProps = {};

export const InvoiceFilterBarLoader = ({}: InvoiceFilterBarLoaderProps) => {
  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Skeleton height={62} />
      </Grid>

      <Grid
        item
        xs={12}
        lg={4}
      >
        <Skeleton height={62} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
      >
        <Skeleton height={62} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
      >
        <Skeleton height={62} />
      </Grid>
    </Grid>
  );
};
