import React from "react";
import { MarathonCard } from "../../common/components/MarathonCard";
import { HoldMyOrderToggle } from "./HoldMyOrderToggle";
import { Typography } from "@mui/material";

type SpecialAndBackorderInfoProps = {
  hasBackOrderedProducts?: boolean;
  hasSpecialOrderedProducts?: boolean;
  holdOrder: boolean;
  setHoldOrder: (value: boolean) => void;
};

export const SpecialAndBackorderInfo: React.FC<SpecialAndBackorderInfoProps> = ({
  holdOrder,
  setHoldOrder,
  hasSpecialOrderedProducts,
  hasBackOrderedProducts,
}) => {
  return (
    <MarathonCard>
      {hasSpecialOrderedProducts && (
        <Typography
          variant={"body1"}
          className={"copy"}
          sx={{ pt: 2, lineHeight: 2 }}
        >
          Items that are <strong>special ordered</strong> will be subject to any applicable freight
          charges and will be added to your invoice. Items that are special ordered may not be
          cancelled after confirmation or returned for any reason. These products are not stocked
          and the lead times listed are our <strong>best estimate only</strong>.
        </Typography>
      )}
      {hasSpecialOrderedProducts && hasBackOrderedProducts && <hr />}
      {hasBackOrderedProducts && (
        <HoldMyOrderToggle
          holdOrder={holdOrder}
          setHoldOrder={setHoldOrder}
        />
      )}
    </MarathonCard>
  );
};
