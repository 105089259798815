import React from "react";
import { RequiredTextField } from "../../forms/components/RequiredTextField";
import { AdditionalShippingInformation } from "../types/AdditionalShippingInformation";
import { MarathonTextField } from "../../forms/components/MarathonTextField";
import { MarathonCheckbox } from "../../forms/components/MarathonCheckbox";
import { SecondaryEmailAutocomplete } from "./SecondaryEmailAutocomplete";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { PickupNotificationFields } from "./PickupNotificationFields";

export type AdditionalInformationFieldsProps = {
  fieldNames: Record<keyof AdditionalShippingInformation, string>;
};

export const AdditionalInformationFields = ({ fieldNames }: AdditionalInformationFieldsProps) => {
  return (
    <>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap={2}
      >
        <RequiredTextField
          name={fieldNames.customerPoNumber}
          textFieldProps={{ label: "Purchasing Reference (PO #)", sx: { flex: 1 } }}
        />
        <SecondaryEmailAutocomplete
          name={fieldNames.secondaryEmails}
          textFieldProps={{
            label: "Secondary email addresses (3 maximum)",
          }}
        />
      </Stack>
      <Stack
        direction={{ xs: "column-reverse", lg: "row" }}
        gap={2}
        pt={2}
      >
        {" "}
        <MarathonTextField
          name={fieldNames.orderComment}
          textFieldProps={{
            label: "Order Notes/Message",
            multiline: true,
            minRows: 3,
            sx: { flex: 1 },
          }}
        />
        <Box sx={{ flex: 1 }}>
          <MarathonCheckbox
            name={fieldNames.saveSecondaryEmails}
            label={"Remember email address(es) for future orders?"}
          />
        </Box>
      </Stack>
      <Divider />
      <PickupNotificationFields fieldNames={fieldNames} />
      <Box
        pt={2}
        pb={4}
      >
        <MarathonCheckbox
          name={fieldNames.rememberNotificationDetails}
          label={"Remember notification details for future orders"}
        />
      </Box>
    </>
  );
};
