import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import dayjs from "dayjs";
import { Invoice } from "../types/Invoice";

export function useUrgencyChecks(invoice: Invoice) {
    const theme = useTheme();

    const isPaidInFull = useMemo(() => invoice.isPaidInFull, [invoice.isPaidInFull]);

    const isPastDue = useMemo(
        () => !isPaidInFull && dayjs().isAfter(invoice.dueDate),
        [invoice.dueDate, isPaidInFull]
    );
    const isDueSoon = useMemo(
        () =>
            !isPaidInFull &&
            dayjs(invoice.dueDate).isBefore(dayjs(invoice.dueDate)) &&
            dayjs(invoice.dueDate).diff(dayjs(), "weeks", true) < 1,
        [invoice.dueDate, isPaidInFull]
    );
    const isNonUrgent = useMemo(
        () => isPaidInFull || (!isPastDue && !isDueSoon),
        [isPaidInFull, isPastDue, isDueSoon]
    );

    const urgencyColor = useMemo(() => {
        if (isPastDue) {
            return theme.palette.error.main;
        } else if (isDueSoon) {
            return theme.palette.warning.main;
        } else if (isPaidInFull) {
            return theme.palette.success.main;
        } else {
            return undefined;
        }
    }, [isDueSoon, isPastDue, isPaidInFull]);

    return {
        isPaidInFull,
        isPastDue,
        isDueSoon,
        isNonUrgent,
        urgencyColor,
    };
}
