import React, { useMemo } from "react";
import Skeleton from "@mui/material/Skeleton";
import { toFixedDollarValue } from "../utils/toFixedDollarValue";
import { useTotalTitle } from "../hooks/useTotalTitle";
import { CommonOrderSummaryProps } from "../types/CommonOrderSummaryProps";
import { Vouchers } from "../../vouchers/components/Vouchers";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type OrderTotalCostsProps = CommonOrderSummaryProps & {
  isPriceUpdating?: boolean;
  areVouchersEditable?: boolean;
};

export const OrderTotalCosts = ({
  cart,
  isLoading: _isLoading,
  isPriceUpdating,
  areVouchersEditable,
}: OrderTotalCostsProps) => {
  const totalTitle = useTotalTitle(cart);
  const isLoading = useMemo(() => _isLoading || isPriceUpdating, [_isLoading, isPriceUpdating]);

  return (
    <>
      <div className="inner-split">
        Subtotal
        {isLoading ? <Skeleton height={22} width={85} /> : <strong>{toFixedDollarValue(cart?.subTotal)}</strong>}
      </div>
      <div className="inner-split">
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={"18px"} textAlign={"center"}>
            Shipping
          </Typography>
          <Box width={"18px"} height={"18px"} display={"flex"} alignItems={"center"}>
            <Tooltip title={"Shipping costs will be calculated at checkout."} placement={"right"}>
              <img src={"/static/cdboxV2/images/icon-tooltip.svg"} alt={"tooltip"} height={18} width={18} />
            </Tooltip>
          </Box>
        </Stack>
        {isLoading ? (
          <Skeleton height={22} width={85} />
        ) : (
          <strong>{toFixedDollarValue(cart?.shippingCost) ?? "N/A"}</strong>
        )}
      </div>
      <div className="inner-split">
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={"18px"} textAlign={"center"}>
            Estimated Sales Tax
          </Typography>
          <Box width={"18px"} height={"18px"} display={"flex"} alignItems={"center"}>
            <Tooltip title={"Sales tax will be calculated at checkout."} placement={"right"}>
              <img src={"/static/cdboxV2/images/icon-tooltip.svg"} alt={"tooltip"} height={18} width={18} />
            </Tooltip>
          </Box>
        </Stack>
        {isLoading ? (
          <Skeleton height={22} width={85} />
        ) : (
          <strong>{toFixedDollarValue(cart?.estimatedSalesTax) ?? "N/A"}</strong>
        )}
      </div>
      <Vouchers isEditable={areVouchersEditable} />
      <hr />
      <div className="inner-split total">
        <strong>{totalTitle}</strong>
        {isLoading ? (
          <Skeleton height={22} width={125} />
        ) : (
          <strong>{toFixedDollarValue(cart?.orderTotal) ?? "N/A"}</strong>
        )}
      </div>
      {cart && cart.totalSavings > 0 ? (
        <div className="inner-split total">
          <strong>Total Savings</strong>
          {isLoading ? <Skeleton height={22} width={125} /> : <strong>{toFixedDollarValue(cart.totalSavings)}</strong>}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
