import React from "react";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { MarathonButton } from "./MarathonButton";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const CustomDatePickerHeader = (
  props: React.PropsWithChildren<PickersCalendarHeaderProps<Dayjs>>
) => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={2}
    >
      <Grid
        item
        xs={2}
      >
        {props.view === "day" && (
          <IconButton
            color={"info"}
            onClick={() => props.onMonthChange(props.currentMonth.subtract(1, "month"), "right")}
          >
            <NavigateBeforeIcon />
          </IconButton>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        display={"flex"}
        justifyContent={"center"}
      >
        <MarathonButton
          color={"secondary"}
          sx={{ p: 1 }}
          onClick={() => props?.onViewChange?.("year")}
          variant={"text"}
        >
          <Typography fontSize={16}>{props.currentMonth.format("MMMM YYYY")}</Typography>
        </MarathonButton>
      </Grid>
      <Grid
        item
        xs={2}
      >
        {props.view === "day" && (
          <IconButton
            color={"info"}
            onClick={() => props.onMonthChange(props.currentMonth.add(1, "month"), "left")}
          >
            <NavigateNextIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
