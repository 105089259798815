import React from "react";

export const NewToMarathon = () => {
  return (
    <>
      <h2>New to Marathon?</h2>
      {/*<a*/}
      {/*  href="/register/showroom"*/}
      {/*  className="menu-cta"*/}
      {/*>*/}
      {/*  <div className="content">*/}
      {/*    <div className="title">Create Showroom account</div>*/}
      {/*    <div className="copy">*/}
      {/*      <p>Looking to window shop? Create projects, save items & view pricing details.</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <svg*/}
      {/*    width="42"*/}
      {/*    height="42"*/}
      {/*    viewBox="0 0 42 42"*/}
      {/*    fill="none"*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*  >*/}
      {/*    <g>*/}
      {/*      <circle*/}
      {/*        cx="21.0005"*/}
      {/*        cy="21"*/}
      {/*        r="20.2632"*/}
      {/*        transform="rotate(-180 21.0005 21)"*/}
      {/*        fill="white"*/}
      {/*      />*/}
      {/*      <circle*/}
      {/*        cx="21"*/}
      {/*        cy="21"*/}
      {/*        r="12"*/}
      {/*        transform="rotate(-180 21 21)"*/}
      {/*        fill="#DA291C"*/}
      {/*      />*/}
      {/*      <path*/}
      {/*        d="M21 36C17.0218 36 13.2064 34.4723 10.3934 31.753C7.58035 29.0338 6 25.3456 6 21.5C6 17.6544 7.58035 13.9662 10.3934 11.2469C13.2064 8.52767 17.0218 7 21 7C24.9783 7 28.7936 8.52767 31.6066 11.2469C34.4196 13.9662 36 17.6544 36 21.5C36 25.3456 34.4196 29.0338 31.6066 31.753C28.7936 34.4723 24.9782 36 21 36ZM27.5625 22.4062C27.8111 22.4062 28.0496 22.3108 28.2254 22.1408C28.4012 21.9709 28.5 21.7404 28.5 21.5C28.5 21.2596 28.4012 21.0291 28.2254 20.8592C28.0496 20.6892 27.8111 20.5937 27.5625 20.5937L16.7006 20.5937L20.7263 16.7041C20.8134 16.6199 20.8826 16.5198 20.9297 16.4097C20.9769 16.2997 21.0012 16.1817 21.0012 16.0625C21.0012 15.9433 20.9769 15.8253 20.9297 15.7153C20.8826 15.6052 20.8134 15.5051 20.7263 15.4209C20.6391 15.3366 20.5356 15.2698 20.4217 15.2242C20.3078 15.1786 20.1858 15.1551 20.0625 15.1551C19.9392 15.1551 19.8172 15.1786 19.7033 15.2242C19.5894 15.2698 19.4859 15.3366 19.3988 15.4209L13.7738 20.8584C13.6864 20.9426 13.6172 21.0426 13.5699 21.1527C13.5227 21.2628 13.4983 21.3808 13.4983 21.5C13.4983 21.6192 13.5227 21.7372 13.5699 21.8473C13.6172 21.9574 13.6864 22.0574 13.7738 22.1416L19.3988 27.5791C19.4859 27.6634 19.5894 27.7302 19.7033 27.7758C19.8172 27.8214 19.9392 27.8449 20.0625 27.8449C20.1858 27.8449 20.3078 27.8214 20.4217 27.7758C20.5356 27.7302 20.6391 27.6634 20.7263 27.5791C20.8134 27.4949 20.8826 27.3948 20.9297 27.2847C20.9769 27.1747 21.0012 27.0567 21.0012 26.9375C21.0012 26.8183 20.9769 26.7003 20.9297 26.5903C20.8826 26.4802 20.8134 26.3801 20.7263 26.2959L16.7006 22.4062L27.5625 22.4062Z"*/}
      {/*        fill="white"*/}
      {/*      />*/}
      {/*    </g>*/}
      {/*    <defs>*/}
      {/*      <clipPath>*/}
      {/*        <rect*/}
      {/*          width="42"*/}
      {/*          height="42"*/}
      {/*          fill="white"*/}
      {/*          transform="translate(42 42) rotate(-180)"*/}
      {/*        />*/}
      {/*      </clipPath>*/}
      {/*    </defs>*/}
      {/*  </svg>*/}
      {/*</a>*/}
      <a
        href="/register/new"
        className="menu-cta"
      >
        <div className="content">
          <div className="title">Create Buyers account</div>
          <div className="copy">
            <p>
              Looking to order online?Everything in the showroom account plus the ability to make
              purchases online.{" "}
            </p>
          </div>
        </div>
        <svg
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <circle
              cx="21.0005"
              cy="21"
              r="20.2632"
              transform="rotate(-180 21.0005 21)"
              fill="white"
            />
            <circle
              cx="21"
              cy="21"
              r="12"
              transform="rotate(-180 21 21)"
              fill="#DA291C"
            />
            <path
              d="M21 36C17.0218 36 13.2064 34.4723 10.3934 31.753C7.58035 29.0338 6 25.3456 6 21.5C6 17.6544 7.58035 13.9662 10.3934 11.2469C13.2064 8.52767 17.0218 7 21 7C24.9783 7 28.7936 8.52767 31.6066 11.2469C34.4196 13.9662 36 17.6544 36 21.5C36 25.3456 34.4196 29.0338 31.6066 31.753C28.7936 34.4723 24.9782 36 21 36ZM27.5625 22.4062C27.8111 22.4062 28.0496 22.3108 28.2254 22.1408C28.4012 21.9709 28.5 21.7404 28.5 21.5C28.5 21.2596 28.4012 21.0291 28.2254 20.8592C28.0496 20.6892 27.8111 20.5937 27.5625 20.5937L16.7006 20.5937L20.7263 16.7041C20.8134 16.6199 20.8826 16.5198 20.9297 16.4097C20.9769 16.2997 21.0012 16.1817 21.0012 16.0625C21.0012 15.9433 20.9769 15.8253 20.9297 15.7153C20.8826 15.6052 20.8134 15.5051 20.7263 15.4209C20.6391 15.3366 20.5356 15.2698 20.4217 15.2242C20.3078 15.1786 20.1858 15.1551 20.0625 15.1551C19.9392 15.1551 19.8172 15.1786 19.7033 15.2242C19.5894 15.2698 19.4859 15.3366 19.3988 15.4209L13.7738 20.8584C13.6864 20.9426 13.6172 21.0426 13.5699 21.1527C13.5227 21.2628 13.4983 21.3808 13.4983 21.5C13.4983 21.6192 13.5227 21.7372 13.5699 21.8473C13.6172 21.9574 13.6864 22.0574 13.7738 22.1416L19.3988 27.5791C19.4859 27.6634 19.5894 27.7302 19.7033 27.7758C19.8172 27.8214 19.9392 27.8449 20.0625 27.8449C20.1858 27.8449 20.3078 27.8214 20.4217 27.7758C20.5356 27.7302 20.6391 27.6634 20.7263 27.5791C20.8134 27.4949 20.8826 27.3948 20.9297 27.2847C20.9769 27.1747 21.0012 27.0567 21.0012 26.9375C21.0012 26.8183 20.9769 26.7003 20.9297 26.5903C20.8826 26.4802 20.8134 26.3801 20.7263 26.2959L16.7006 22.4062L27.5625 22.4062Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath>
              <rect
                width="42"
                height="42"
                fill="white"
                transform="translate(42 42) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    </>
  );
};
