import React, { useEffect, useState } from "react";
import { ProviderResponse, TwoFactorAuthType } from "../../types/TwoFactor";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const dayjsDuration = dayjs.duration;

export const ProviderInformation = ({
  providerData,
  reloadProviderData,
}: {
  providerData: ProviderResponse;
  reloadProviderData: () => Promise<void>;
}) => {
  const [lockedForSeconds, setLockedForSeconds] = useState<number | null>(null);

  useEffect(() => {
    if (providerData?.lockedForSeconds && providerData.lockedForSeconds > 0) {
      setLockedForSeconds(providerData.lockedForSeconds);
    } else {
      setLockedForSeconds(null);
    }
  }, [JSON.stringify(providerData)]);

  useEffect(() => {
    setTimeout(() => {
      if (lockedForSeconds !== null && lockedForSeconds !== undefined) {
        if (lockedForSeconds <= 0) {
          reloadProviderData();
        } else {
          setLockedForSeconds(lockedForSeconds - 1);
        }
      }
    }, 1000);
  }, [lockedForSeconds]);

  const getProviderInformation = () => {
    if (providerData?.currentProvider === TwoFactorAuthType.Phone) {
      return (
        <div style={{ paddingBottom: "5px" }}>
          <div className="sent-to">Enter text code sent to:</div>
          <div className="destination">{providerData.phone}</div>
        </div>
      );
    } else if (providerData?.currentProvider === TwoFactorAuthType.Email) {
      return (
        <div style={{ paddingBottom: "5px" }}>
          <div className="sent-to">Enter email code sent to:</div>
          <div className="destination">{providerData.email}</div>
        </div>
      );
    } else if (providerData?.currentProvider === TwoFactorAuthType.Authenticator) {
      return (
        <div style={{ paddingBottom: "5px" }}>
          <div className="sent-to">Enter code found in your Authenticator App</div>
        </div>
      );
    }

    return <></>;
  };

  return providerData ? (
    <>
      {providerData.locked && lockedForSeconds !== null && lockedForSeconds > 0 && (
        <p style={{ color: "red" }}>
          2FA locked for: {dayjsDuration(lockedForSeconds, "seconds").format("m:ss")}
        </p>
      )}
      {getProviderInformation()}
    </>
  ) : (
    <></>
  );
};
