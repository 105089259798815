import React from "react";
import { PickupFields } from "../types/PickupFields";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { MarathonCheckbox } from "../../forms/components/MarathonCheckbox";
import { PickupNotificationFields } from "./PickupNotificationFields";
import { PickupDateTimeFields } from "./PickupDateTimeFields";
import { ShippingInformation } from "../types/Checkout";

export type PickupInShowroomFieldsProps = {
  shippingInformation: ShippingInformation;
  fieldNames: Record<keyof PickupFields, string>;
};

export const PickupInShowroomFields = ({
  shippingInformation,
  fieldNames,
}: PickupInShowroomFieldsProps) => {
  return (
    <>
      <PickupDateTimeFields
        shippingInformation={shippingInformation}
        fieldNames={fieldNames}
      />
    </>
  );
};
