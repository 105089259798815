import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Field, FieldProps } from "react-final-form";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material/TextField";

export const MARATHON_DATE_FORMAT = "MM-DD-YYYY";

export type DatePickerFieldProps = {
  name: string;
  fieldProps?: FieldProps<any, any>;
  datePickerProps?: DatePickerProps<Dayjs>;
  textFieldProps?: TextFieldProps;
};

export const DatePickerField = ({
  name,
  fieldProps,
  datePickerProps,
  textFieldProps,
}: DatePickerFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Field
        name={name}
        {...fieldProps}
        render={({ input, meta }) => (
          <>
            <DatePicker
              {...datePickerProps}
              open={isOpen}
              value={dayjs(input.value)}
              format={MARATHON_DATE_FORMAT}
              onChange={(value) => input.onChange(value?.format(MARATHON_DATE_FORMAT))}
              onClose={() => setIsOpen(false)}
              slotProps={{
                textField: {
                  ...textFieldProps,
                  inputProps: input,
                  onClick: () => setIsOpen(true),
                  error: meta.invalid && meta.error,
                  helperText: meta.invalid ? meta.error : undefined,
                },
              }}
            />
          </>
        )}
      />
    </>
  );
};
