import React from "react";
import { MarathonSelectField, MarathonSelectFieldProps } from "./MarathonSelectField";
import MenuItem from "@mui/material/MenuItem";

export type EnumSelectorProps = Omit<MarathonSelectFieldProps, "children"> & {
  options: object;
};

export const EnumSelector = ({ options, ...props }: EnumSelectorProps) => {
  return (
    <MarathonSelectField {...props}>
      {Object.entries(options).map(([key, value]) => {
        return (
          <MenuItem
            key={key}
            value={key}
          >
            {value}
          </MenuItem>
        );
      })}
    </MarathonSelectField>
  );
};
