// https://stackoverflow.com/a/54043991

import { User } from "../types/User";
import { CustomerType } from "../types/CustomerType";

const CUSTOMER_TYPE_ORDER = Object.values(CustomerType);

export function sortUserAccounts(a: User, b: User) {
    const needsApprovalSort = a.needsApproval && !b.needsApproval ? -1 : 1;

    if(a.needsApproval || b.needsApproval){
        return needsApprovalSort || sortByCustomerType(a.customerType, b.customerType) || sortByString(a.email, b.email);
    }

    if(a.enabled && b.enabled){
        return (
            sortByCustomerType(a.customerType, b.customerType) ||
            sortByString(a.lastName || '', b.lastName || '')
        );
    }

    //If not enabled, just sort 
    if(!a.enabled && !b.enabled){
        return (needsApprovalSort || sortByCustomerType(a.customerType, b.customerType) || sortByString(a.email, b.email));
    }else{
        return needsApprovalSort || a.enabled && !b.enabled ? -1 : 1;
    }
}

function sortByCustomerType(customerTypeA: CustomerType, customerTypeB: CustomerType){
    return (CUSTOMER_TYPE_ORDER.indexOf(customerTypeA) - CUSTOMER_TYPE_ORDER.indexOf(customerTypeB));
}

function sortByString(stringA: string, stringB: string){
    return (stringA[0] || '' > stringB[0] ? 1 : 0);
}
