import { useEffect, useState } from "react";
import { CheckoutSteps } from "../Checkout";

export function useCheckoutStep(checkoutPath: CheckoutSteps) {
    const [checkoutStep, setCheckoutStep] = useState(checkoutPath);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [checkoutStep]);

    return { checkoutStep, setCheckoutStep };
}
